import {LOCALE_ID, NgModule} from '@angular/core';
import {CommonModule, DatePipe, registerLocaleData} from '@angular/common';
import {profileRoutes} from './profile-routing.module';
import {RouterModule} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgbDateAdapter, NgbDateNativeAdapter} from '@ng-bootstrap/ng-bootstrap';
import localeNl from '@angular/common/locales/nl';

import {
  AssignmentFormComponent,
  AssignmentsComponent,
  CertificateFormComponent,
  CertificatesComponent,
  EducationComponent,
  EducationFormComponent,
  InterestFormComponent,
  InterestsComponent,
  LanguageFormComponent,
  LanguagesComponent,
  PersonalDetailsComponent,
  PersonalDetailsFormComponent,
  PhotoComponent,
  SoftSkillsComponent,
  SoftSkillFormComponent,
  SummaryComponent,
  SummaryFormComponent,
  ToolFormComponent,
  ToolsComponent
} from './components/index';

import {DragDropModule} from '@angular/cdk/drag-drop';

import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import {TranslateModule} from '@ngx-translate/core';

import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MatMomentDateModule} from '@angular/material-moment-adapter';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {ErrorStateMatcher, ShowOnDirtyErrorStateMatcher} from '@angular/material/core';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatCardModule} from '@angular/material/card';
import {MatListModule} from '@angular/material/list';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatSelectModule} from '@angular/material/select';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatIconModule} from '@angular/material/icon';

import {FileUploadModule} from "ng2-file-upload";

import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";

registerLocaleData(localeNl);

@NgModule({
  declarations: [
    SummaryComponent,
    AssignmentsComponent,
    ToolsComponent,
    EducationComponent,
    CertificatesComponent,
    InterestsComponent,
    LanguagesComponent,
    PersonalDetailsComponent,
    PersonalDetailsFormComponent,
    SummaryFormComponent,
    AssignmentFormComponent,
    EducationFormComponent,
    CertificateFormComponent,
    LanguageFormComponent,
    InterestFormComponent,
    ToolFormComponent,
    PhotoComponent,
    SoftSkillsComponent,
    SoftSkillFormComponent
  ],
  exports: [
    PersonalDetailsFormComponent,
    SummaryFormComponent
  ],
    imports: [
        RouterModule.forChild(profileRoutes),
        CommonModule,

        FormsModule,
        ReactiveFormsModule,

        MatFormFieldModule,
        MatDatepickerModule,
        BrowserModule,
        BrowserAnimationsModule,
        MatInputModule,
        MatButtonModule,
        MatMomentDateModule,
        MatExpansionModule,
        MatCardModule,
        MatListModule,
        DragDropModule,
        MatCheckboxModule,
        MatSelectModule,
        MatSidenavModule,
        MatToolbarModule,
        MatIconModule,

        FileUploadModule,

        TranslateModule.forChild(),
        MatProgressSpinnerModule

    ],
  providers: [
    {provide: LOCALE_ID, useValue: 'nl-NL'},
    {provide: NgbDateAdapter, useClass: NgbDateNativeAdapter},
    {provide: ErrorStateMatcher, useClass: ShowOnDirtyErrorStateMatcher},
    {provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: {useUtc: true}},
    {provide: DatePipe, useClass: DatePipe}
  ]
})

export class ProfileModule {
}

