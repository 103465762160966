import {inject} from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot } from '@angular/router';
import {OidcSecurityService} from 'angular-auth-oidc-client';
import {filter, map} from 'rxjs/operators';

export const authenticationGuard : CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  const oidcSecurityService = inject(OidcSecurityService);

  return oidcSecurityService.isAuthenticated$.pipe(
    map(result => result.isAuthenticated),
    filter(isAuthenticated => isAuthenticated)
  );
}
