import {Component, OnInit} from '@angular/core';

import {Router} from "@angular/router";
import {OidcSecurityService} from "angular-auth-oidc-client";
import {Observable} from "rxjs";
import { map } from 'rxjs/operators';
import {UserApiService} from "../../core/services/user-api.service";


@Component({
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.css']
})
export class DashboardComponent implements OnInit {
  isAuthenticated$!: Observable<boolean>;
  isProfessional$!: Observable<boolean>;

  constructor(private router: Router, public oidcSecurityService: OidcSecurityService, private userApiService: UserApiService) {
  }

  ngOnInit() {
    this.isProfessional$ = this.userApiService.isProfessional$();
    this.isAuthenticated$ = this.oidcSecurityService.isAuthenticated$.pipe(map(result => result.isAuthenticated));
    
    if (this.isMobile()) {
      this.router.navigate(['/smoelenboek']);
    }
  }

  private isMobile(): boolean {
    // For backwards compatibility with @angular/flex-layout
    // See: https://github.com/angular/flex-layout/wiki/Responsive-API#mediaqueries-and-aliases
    const xs = 600;

    return window.innerWidth < xs;
  }
}
