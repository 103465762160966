import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormGroupDirective, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Tool, ToolLevel, ToolType} from "../../../model/tool";
import {Formcontainer} from "../../../model/formcontainer";

@Component({
  selector: 'app-tool-form',
  templateUrl: './tool-form.component.html',
  styleUrls: ['../../../shared/styles/cv.css']
})
export class ToolFormComponent implements OnInit {
  @Input()
  currentTool: Tool;
  toolForm: UntypedFormGroup;
  tools: UntypedFormArray;


  toolTypes: ToolType[] = Object.keys(ToolType).map(key => ToolType[key]);

  @Output()
  formResult: EventEmitter<Formcontainer> = new EventEmitter();

  @Output()
  deleteTl: EventEmitter<any> = new EventEmitter();

  levels: ToolLevel[] = Object.keys(ToolLevel).map(key => ToolLevel[key]);

  constructor(private fb: UntypedFormBuilder) { }


  ngOnInit() {
    this.toolForm = this.fb.group({
      name: ['', Validators.required],
      toolType: ['', Validators.required],
      level: ['', Validators.required],
      id: [null]
    });
    if (this.currentTool != null) {
      console.log(this.currentTool)
      this.toolForm.patchValue(this.currentTool);
    }
  }

  saveTool(form: UntypedFormGroup, formDirective: FormGroupDirective) {
    this.formResult.emit({form: form, formDirective: formDirective});
  }
  deleteTool() {
    this.deleteTl.emit(this.toolForm)
  }

}
