import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';

import {MatMomentDateModule} from '@angular/material-moment-adapter';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatCardModule} from '@angular/material/card';
import {MatListModule} from '@angular/material/list';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatSelectModule} from '@angular/material/select';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatIconModule} from '@angular/material/icon';

import {DownloadComponent, downloadRoutes} from '../index';
import {ProfileModule} from "../profile/profile.module";
import {MatSnackBarModule} from "@angular/material/snack-bar";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";

@NgModule({
  declarations: [DownloadComponent],
    imports: [
        CommonModule,
        RouterModule.forChild(downloadRoutes),
        ProfileModule,
        FormsModule,
        ReactiveFormsModule,

        MatFormFieldModule,
        MatDatepickerModule,
        MatInputModule,
        MatButtonModule,
        MatMomentDateModule,
        MatExpansionModule,
        MatCardModule,
        MatListModule,
        MatCheckboxModule,
        MatSelectModule,
        MatSidenavModule,
        MatToolbarModule,
        MatIconModule,
        MatSnackBarModule,

        TranslateModule.forChild(),
        MatProgressSpinnerModule

    ]
})
export class DownloadModule { }
