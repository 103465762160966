import {Component} from '@angular/core';
import {UntypedFormBuilder} from '@angular/forms';
import {ProfileApiService} from "../../../core/services/profile-api.service";
import {Profile} from "../../../model/profile";
import {ActivatedRoute, Router} from '@angular/router';
import {Group} from "../../../model/group";
import {DatePipe} from "@angular/common";
import {Formcontainer} from "../../../model/formcontainer";


@Component({
  templateUrl: './personal-details.component.html',
  styleUrls: ['../../../shared/styles/cv.css'],
})
export class PersonalDetailsComponent {
  public errorMessage: string;
  readonly currentProfile: Profile;
  readonly availableGroups: Group[];

  constructor(private route: ActivatedRoute, private router: Router, private fb: UntypedFormBuilder, private api: ProfileApiService, private datePipe: DatePipe) {
    this.currentProfile = route.snapshot.data.currentProfile;
    this.availableGroups = route.snapshot.data.allGroups;
  }

  public savePersonalDetails(form: Formcontainer) {
    if (form.form.valid) {
      if (form.form.dirty) {
        const p = {...this.currentProfile}

        p.professional = {...form.form.value};
        //add the values of the groups, not just the booleans
        p.professional.groups = [];
        form.form.controls['groups'].value.forEach((g, i) => {
          if (g) {
            p.professional.groups.push(this.availableGroups[i].name)
          }
        })
        if (p.professional.dateOfBirth) {
          p.professional.dateOfBirth = this.datePipe.transform(p.professional.dateOfBirth, 'yyyy-MM-dd');
        }
        this.api.updateProfile(p).subscribe({
          next: () => this.onSaveComplete(form),
          error: err => this.errorMessage = err.messsage
        });
      } else {
        this.onSaveComplete(form);
      }
    } else {
      this.errorMessage = 'correct.values';
    }
  }

  private onSaveComplete(form: Formcontainer): void {
    // Reset the form to clear the flags
    form.form.markAsPristine();
    this.errorMessage = undefined;
  }
}
