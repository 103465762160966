<form class="d-flex flex-column max-w-50p-on-pc" novalidate [formGroup]="certificateForm" (ngSubmit)="saveCertificate(certificateForm, formDirective)" #formDirective="ngForm">
  <mat-form-field class="flex">
    <mat-label>{{ 'cer.Certificate' | translate }}</mat-label>
    <input matInput formControlName="name">
  </mat-form-field>

  <mat-form-field class="flex">
    <mat-label>{{ 'cer.IssueDate' | translate }}</mat-label>
    <input matInput [matDatepicker]="endDate" formControlName="issueDate">
    <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
    <mat-datepicker #endDate startView="multi-year"></mat-datepicker>
  </mat-form-field>
  <mat-form-field class="flex">
    <mat-label>{{ 'cer.ExpirationDate' | translate }}</mat-label>
    <input matInput [matDatepicker]="startDate" formControlName="expirationDate">
    <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
    <mat-datepicker #startDate startView="multi-year"></mat-datepicker>
  </mat-form-field>
  <mat-checkbox  formControlName="graduated">{{ (certificateForm.get('graduated').value ? 'cer.Graduated' : 'cer.NotGraduated') | translate }}</mat-checkbox>
  <br/>
  <div class="d-flex gap-10">
    <button type="submit" mat-raised-button color="primary" [disabled]="certificateForm.pristine">{{'cer.Save' | translate}}</button>
    <button type="button" *ngIf="certificateForm.controls['id'].value" mat-raised-button color="warn" (click)="deleteCertificate()">{{'cer.Delete' | translate}}</button>
  </div>
</form>
