<form class="d-flex flex-column max-w-50p-on-pc" novalidate (ngSubmit)="saveSummary(summaryForm, formDirective)" [formGroup]="summaryForm" #formDirective="ngForm">
  <mat-form-field class="flex-fill">
    <mat-label>{{ 'sum.name' | translate }}</mat-label>
    <input matInput formControlName="name">
    <mat-hint translate>sum.hint</mat-hint>
  </mat-form-field>
  <br />
  <div class="d-flex flex-row gap-20">
    <mat-form-field class="flex">
      <mat-label>{{ 'sum.Summary' | translate }}</mat-label>
      <textarea matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="20" formControlName="summaryNL"></textarea>
      <mat-hint translate>NL</mat-hint>
    </mat-form-field>
    <mat-form-field class="flex">
      <mat-label>{{ 'sum.Summary' | translate }}</mat-label>
      <textarea matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="20" formControlName="summaryEN"></textarea>
      <mat-hint translate>EN</mat-hint>
    </mat-form-field>
  </div>
  <br/>
  <div class="d-flex gap-10">
    <button type="submit" mat-raised-button color="primary" [disabled]="summaryForm.pristine" >{{'sum.Save' | translate}}</button>
    <button type="button" *ngIf="summaryForm.controls['id'].value" mat-raised-button color="warn" (click)="deleteSummary()">{{'sum.Delete' | translate}}</button>
  </div>
</form>
