import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, ReplaySubject } from 'rxjs';
import { User } from "../../model/user";
import { Group } from "../../model/group";
import { first, map } from "rxjs/operators";
import { Role } from "../../model/role";


@Injectable({
  providedIn: 'root'
})
export class UserApiService {
  private userSubject = new ReplaySubject<User>(1);
  private userRequested = false;

  private allUsersSubject = new ReplaySubject<User[]>(1);
  private allUsersRequested = false;

  private allGroupsSubject = new ReplaySubject<Group[]>(1);
  private allGroupsRequested = false;

  constructor(private http: HttpClient) {
  }

  public getAllUsers(): Observable<User[]> {
    if (!this.allUsersRequested) {
      this.allUsersRequested = true;
      this.http
        .get<User[]>(`/api/users`)
        .subscribe(users => this.allUsersSubject.next(users));
    }

    return this.allUsersSubject.pipe(first());
  }

  public getCurrentUser(): Observable<User> {
      if (!this.userRequested) {
        this.userRequested = true;
        this.http
          .get<User>(`/api/users/currentUser`)
          .subscribe(nr => this.userSubject.next(nr));
      }
      return this.userSubject.pipe(first());
  }

  public getUser(userId): Observable<User> {
    return this.http.get<User>(`/api/users/${userId}`);
  }

  public updateUser(user: User): Observable<User> {
    return this.http.post<User>('/api/users', user)
  }

  public addUser(user: User): Observable<User> {
    return this.http.put<User>('/api/users', user)
  }

  public deleteUser(user: User): Observable<User> {
    return this.http.delete<User>(`/api/users/${user.id}`)
  }

  public getAllGroups(): Observable<Group[]> {
    if (!this.allGroupsRequested) {
      this.allGroupsRequested = true;
      this.http
        .get<Group[]>(`/api/groups`)
        .subscribe(groups => this.allGroupsSubject.next(groups));
    }
    return this.allGroupsSubject.pipe(first());
  }

  public addGroup(group: Group): Observable<Group> {
    return this.http.put<Group>('/api/groups', group)
  }

  public deleteGroup(group: Group): Observable<Group> {
    return this.http.delete<Group>(`/api/groups/${group.id}`)
  }

  public isSales$(): Observable<boolean> {
    return this
      .getCurrentUser()
      .pipe(map(u => u.roles.indexOf(Role.SALES) > -1));
  }

  public isAdmin$(): Observable<boolean> {
    return this
      .getCurrentUser()
      .pipe(map(u => u.roles.indexOf(Role.ADMIN) > -1));
  }

  public isProfessional$(): Observable<boolean> {
     return this
      .getCurrentUser()
      .pipe(map(u => u.roles.indexOf(Role.PROFESSIONAL) > -1));
  }
}
