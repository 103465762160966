<mat-card>
  <mat-card-content>
    <h3>{{'side.Certificates' | translate}}  {{currentProfile.professional.firstName + " " + currentProfile.professional.lastName}}</h3>
    <mat-card *ngIf="errorMessage">
      <mat-card-content>
        <mat-error>{{errorMessage | translate}}</mat-error>
      </mat-card-content>
    </mat-card>
    <mat-accordion multi="true" cdkDropList (cdkDropListDropped)="dropCertificate($event)">
      <mat-expansion-panel expanded="{{currentProfile.certificates.length < 2}}">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <strong>{{'cer.Add' | translate}}</strong>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
          <app-certificate-form [currentCertificate]={} (formResult)="saveCertificate($event)"> </app-certificate-form>
        </ng-template>
      </mat-expansion-panel>

      <mat-expansion-panel *ngFor="let certificate of currentProfile.certificates" class="cdk-cursor" cdkDrag>
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{certificate.name}}
          </mat-panel-title>
          <mat-panel-description>
            {{certificate.issueDate}}
          </mat-panel-description>
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
          <app-certificate-form [currentCertificate]="certificate" (formResult)="saveCertificate($event)" (deleteCert)="deleteCertificate($event)"> </app-certificate-form>
        </ng-template>
      </mat-expansion-panel>
    </mat-accordion>
    <div class="drag-drop-text-container">
      <mat-hint *ngIf="currentProfile.certificates.length > 1" translate>DragDrop</mat-hint>
    </div>
  </mat-card-content>
</mat-card>
