import {Component, OnInit} from '@angular/core';
import {OidcSecurityService} from 'angular-auth-oidc-client';
import {Observable} from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
    selector: 'app-forbidden',
    templateUrl: 'forbidden.component.html',
})
export class ForbiddenComponent implements OnInit {
    public authenticated$: Observable<boolean>;

    constructor(private oidcSecurityService: OidcSecurityService) {}

    ngOnInit() {
        this.authenticated$ = this.oidcSecurityService.isAuthenticated$.pipe(map(result => result.isAuthenticated));
    }
}
