<form class="d-flex flex-column gap-10 max-w-50p-on-pc" novalidate [formGroup]="softSkillForm" (ngSubmit)="saveSoftSkill(softSkillForm, formDirective)" #formDirective="ngForm">

  <div class="d-flex flex-row gap-20">
    <mat-form-field class="flex">
      <mat-label>{{ 'softSkill.ShortName' | translate }}</mat-label>
      <input matInput formControlName="nameSoftSkillNL">
      <mat-hint translate>NL</mat-hint>
    </mat-form-field>
    <mat-form-field class="flex">
      <mat-label>{{ 'softSkill.ShortName' | translate }}</mat-label>
      <input matInput formControlName="nameSoftSkillEN">
      <mat-hint translate>EN</mat-hint>
    </mat-form-field>
  </div>

  <mat-form-field>
    <mat-label translate>softSkill.Level</mat-label>
    <mat-select formControlName="level">
      <mat-option *ngFor="let level of levels" [value]="level">
        {{"softSkill." + level | translate}}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <div class="d-flex gap-10">
    <button type="submit" mat-raised-button color="primary" [disabled]="softSkillForm.pristine">{{'softSkill.Save' | translate}}</button>
    <button type="button" *ngIf="softSkillForm.controls['nameSoftSkillNL'].value && softSkillForm.controls['nameSoftSkillEN'].value" mat-raised-button color="warn" (click)="deleteSoftSkill()">{{'softSkill.Delete' | translate}}</button>
  </div>
</form>
