@if (loaded) {
  <div class="flex-fill">
    <app-nav-bar (navbarOpenEmitter)="toggleSidebar($event)" (navbarModeEmitter)="setSidebarMode($event)"></app-nav-bar>
    <mat-sidenav-container class="fill-screen-minus-navbar" autosize>
      <mat-sidenav mode="{{sidebarMode}}" opened="{{sidebarOpen}}" class="flex">
        <app-side-bar></app-side-bar>
      </mat-sidenav>
      <mat-sidenav-content class="flex">
          <router-outlet></router-outlet>
      </mat-sidenav-content>
    </mat-sidenav-container>
  </div>
}
@if (!loaded || !isAuthenticated) {
  <div class="loading-overlay">
    <mat-spinner></mat-spinner>
  </div>
}
