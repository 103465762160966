export * from './assignment-form/assignment-form.component';
export * from './assignments/assignments.component';

export * from './certificate-form/certificate-form.component';
export * from './certificates/certificates.component';

export * from './education/education.component';
export * from './education-form/education-form.component';

export * from './interest/interests.component';
export * from './interest-form/interest-form.component';

export * from './language-form/language-form.component';
export * from './languages/languages.component';

export * from './personal-details/personal-details.component';
export * from './personal-details-form/personal-details-form.component';

export * from './summary/summary.component';
export * from './summary-form/summary-form.component';

export * from './tool-form/tool-form.component';
export * from './tools/tools.component';

export * from './photo/photo.component';

export * from './soft-skill-form/soft-skill-form.component'
export * from './soft-skills/soft-skills.component'
