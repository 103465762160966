import {Routes} from '@angular/router';
import {PersonalDetailsComponent} from "./components/personal-details/personal-details.component";
import {
  AssignmentsComponent,
  CertificatesComponent,
  EducationComponent,
  InterestsComponent,
  LanguagesComponent,
  PhotoComponent,
  SummaryComponent,
  ToolsComponent,
  SoftSkillsComponent
} from "./components/index";
import {authenticationGuard} from "../core/authentication/authentication.guard";
import {profileResolver} from "../core/resolvers/profile.resolver";
import {groupsResolver} from "../core/resolvers/groups.resolver";


export const profileRoutes: Routes = [
  {path: 'personal-details', component: PersonalDetailsComponent, canActivate: [authenticationGuard], resolve: {currentProfile: profileResolver, allGroups: groupsResolver}},
  {path: 'summary', component: SummaryComponent, canActivate: [authenticationGuard], resolve: {currentProfile: profileResolver}},
  {path: 'assignments', component: AssignmentsComponent, canActivate: [authenticationGuard], resolve: {currentProfile: profileResolver}},
  {path: 'tools', component: ToolsComponent, canActivate: [authenticationGuard], resolve: {currentProfile: profileResolver}},
  {path: 'soft-skills', component: SoftSkillsComponent, canActivate: [authenticationGuard], resolve: {currentProfile: profileResolver}},
  {path: 'education', component: EducationComponent, canActivate: [authenticationGuard], resolve: {currentProfile: profileResolver}},
  {path: 'certificates', component: CertificatesComponent, canActivate: [authenticationGuard], resolve: {currentProfile: profileResolver}},
  // {path: 'side-activities', component: SideActivitiesComponent, canActivate: [AuthGuard]},
  {path: 'interests', component: InterestsComponent, canActivate: [authenticationGuard], resolve: {currentProfile: profileResolver}},
  {path: 'languages', component: LanguagesComponent, canActivate: [authenticationGuard], resolve: {currentProfile: profileResolver}},
  {path: 'photo', component: PhotoComponent, canActivate: [authenticationGuard], resolve: {currentProfile: profileResolver}},

];
