<mat-toolbar color="primary">
  <mat-toolbar-row>
    <button *ngIf="showToggleNavbar()" mat-icon-button (click)="toggleNavbar()">
      <mat-icon>menu</mat-icon>
    </button>
    <h1>PRO</h1>
    <span class="menu-spacer"></span>
    <div *ngIf="(isAuthenticated$ | async)">
      <a mat-button color="secundary" class="hidden-on-mobile" *ngIf="(isProfessional$ | async)" [routerLink]="['/home']" routerLinkActive="mat-accent" >
        <mat-icon>home</mat-icon>
        <span class="hidden-on-mobile">{{ 'nav.Home'| translate  }}</span>
      </a>
      <a mat-button color="secundary" *ngIf="(isProfessional$ | async)" [routerLink]="['/download']" routerLinkActive="mat-accent">
        <mat-icon>archive</mat-icon>
        <span class="hidden-on-mobile">{{ 'nav.DownloadCV'| translate }}</span>
      </a>
      <a mat-button color="secundary" [routerLink]="['/smoelenboek']" routerLinkActive="mat-accent">
        <mat-icon>group</mat-icon>
        <span class="hidden-on-mobile">{{ 'nav.Facebook'| translate }}</span>
      </a>
      <a mat-button color="secundary" *ngIf="(isAdmin$ | async) || (isSales$ | async)" class="nav-link" [routerLink]="['/admin']" routerLinkActive="mat-accent">
        <mat-icon>settings_applications</mat-icon>
        <span class="hidden-on-mobile">{{ 'nav.Admin'| translate }}</span>
      </a>
    </div>
    <span class="spacer"></span>
    <div class="login-button-container">
      <a *ngIf="!(isAuthenticated$ | async)" (click)="login()" mat-raised-button color="secundary"
         routerLinkActive="active">
        <mat-icon>lock_open</mat-icon>
        <span class="hidden-on-mobile">{{ 'nav.LogIn' | translate }}</span>
      </a>
      <a *ngIf="(isAuthenticated$ | async)" mat-raised-button color="secundary" (click)="logout()">
        <mat-icon>exit_to_app</mat-icon>
        <span class="hidden-on-mobile">{{ 'nav.LogOut' | translate }}</span>
      </a>
    </div>
  </mat-toolbar-row>
</mat-toolbar>
