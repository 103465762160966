import {Routes} from '@angular/router';

import {DashboardComponent} from './components';
import {authenticationGuard} from "./core/authentication/authentication.guard";
import {AutoLoginComponent} from "./core/auto-login/auto-login.component";
import {ForbiddenComponent} from "./core/forbidden/forbidden.component";
import {UnauthorizedComponent} from "./core/unauthorized/unauthorized.component";


export const appRoutes: Routes = [
  {path: 'unauthorized', component: UnauthorizedComponent},
  {path: 'autologin', component: AutoLoginComponent},
  {path: 'forbidden', component: ForbiddenComponent, canActivate: [authenticationGuard]},
  {path: 'home', component: DashboardComponent, canActivate: [authenticationGuard]},
  {path: '', redirectTo: '/home', pathMatch: 'full'}
];
