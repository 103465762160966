<mat-card *ngIf="errorMessage">
  <mat-card-content>
    <mat-error>{{errorMessage | json}}</mat-error>
  </mat-card-content>
</mat-card>

<mat-card *ngIf="(isAdmin$ | async)">
  <mat-card-content>
    <form class="d-flex flex-column gap-10 max-w-50p-on-pc" novalidate [formGroup]="adminForm">
      <h3>Rollen toekennen</h3>
      <mat-form-field class="flex">
        <mat-label translate>cv.ProfileId</mat-label>
        <mat-select formControlName="userId">
          <mat-option *ngFor="let user of availableUsers" [value]="user.id">
            {{user.name}} ({{user.company}})
          </mat-option>
        </mat-select>
      </mat-form-field>
      <div class="d-flex flex-row gap-20" formGroupName="roles">
        <mat-checkbox formControlName="admin" >Admin</mat-checkbox>
        <mat-checkbox formControlName="professional" >Professional</mat-checkbox>
        <mat-checkbox formControlName="sales" >Sales</mat-checkbox>
      </div>
      <button type="button" (click)="saveRoles()" mat-raised-button color="primary" [disabled]="adminForm.pristine">{{'Submit' | translate}}</button>
    </form>
  </mat-card-content>
</mat-card>

<mat-card *ngIf="(isAdmin$ | async) || (isSales$ | async)">
  <mat-card-content>
    <form class="d-flex flex-column gap-10 max-w-50p-on-pc" novalidate [formGroup]="addUserForm" >
      <h3>Gebruiker toevoegen</h3>
      <mat-form-field class="flex">
        <mat-label>Naam</mat-label>
        <input matInput formControlName="name">
      </mat-form-field>
      <mat-form-field class="flex">
        <mat-label>Emailadres</mat-label>
        <input matInput formControlName="email">
      </mat-form-field>
      <mat-form-field class="flex">
        <mat-label>Bedrijf</mat-label>
        <mat-select formControlName="company">
          <mat-option *ngFor="let company of companies" [value]="company">
            {{company}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <button type="button" (click)="addUser()" mat-raised-button color="primary" [disabled]="addUserForm.pristine">{{'Submit' | translate}}</button>
    </form>
  </mat-card-content>
</mat-card>

<mat-card *ngIf="(isAdmin$ | async) ">
  <mat-card-content>
    <form class="d-flex flex-column gap-10 max-w-50p-on-pc" novalidate [formGroup]="deleteUserForm">
      <h3>Gebruiker verwijderen</h3>
      <mat-form-field class="flex">
        <mat-label>Gebruiker</mat-label>
        <mat-select formControlName="id">
          <mat-option *ngFor="let user of availableUsers" [value]="user.id">
            {{user.name}} ({{user.company}})
          </mat-option>
        </mat-select>
      </mat-form-field>
      <button type="button" (click)="deleteUser()" mat-raised-button color="primary" [disabled]="deleteUserForm.pristine">{{'Delete' | translate}}</button>
    </form>
  </mat-card-content>
</mat-card>

<mat-card *ngIf="(isAdmin$ | async)">
  <mat-card-content>
    <form class="d-flex flex-column gap-10 max-w-50p-on-pc" novalidate [formGroup]="addGroupForm">
      <h3>Groep toevoegen</h3>
      <mat-form-field class="flex">
        <mat-label>Naam</mat-label>
        <input matInput formControlName="name">
      </mat-form-field>
      <button type="button" (click)="addGroup()" mat-raised-button color="primary" [disabled]="addGroupForm.pristine">{{'Submit' | translate}}</button>
    </form>
  </mat-card-content>
</mat-card>

<mat-card *ngIf="(isAdmin$ | async)">
  <mat-card-content>
    <form class="d-flex flex-column gap-10 max-w-50p-on-pc" novalidate [formGroup]="deleteGroupForm">
      <h3>Groep verwijderen</h3>
      <mat-form-field class="flex">
        <mat-label>Groep</mat-label>
        <mat-select formControlName="id">
          <mat-option *ngFor="let group of availableGroups" [value]="group.id">
            {{group.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <button type="button" (click)="deleteGroup()" mat-raised-button color="primary" [disabled]="deleteGroupForm.pristine">{{'Delete' | translate}}</button>
    </form>
  </mat-card-content>
</mat-card>
