import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import { HttpClient } from "@angular/common/http";
import {FacebookProfile} from "../model/facebookProfile";

@Injectable({
  providedIn: 'root'
})
export class FacebookService {

  constructor(private http: HttpClient) { }

  getAvailableProfiles(): Observable<FacebookProfile[]> {
    return this.http.get<FacebookProfile[]>(`/api/facebook`);
  }

  getPhoto(photoId: string): Observable<any> {
    return this.http.get(`/api/facebook/photo/${photoId}`,{responseType: 'blob'});
  }
}
