<form class="d-flex flex-column max-w-50p-on-pc" novalidate [formGroup]="toolForm" (ngSubmit)="saveTool(toolForm, formDirective)" #formDirective="ngForm">
  <mat-form-field>
    <mat-label>{{ 'tool.ShortName' | translate }}</mat-label>
    <input matInput formControlName="name">
  </mat-form-field>

  <mat-form-field>
    <mat-label translate>tool.ToolType</mat-label>
    <mat-select formControlName="toolType">
      <mat-option *ngFor="let toolType of toolTypes" [value]="toolType">
        {{"tool." + toolType | translate}}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field>
    <mat-label translate>tool.Level</mat-label>
    <mat-select formControlName="level">
      <mat-option *ngFor="let level of levels" [value]="level">
        {{"tool." + level | translate}}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <div class="d-flex gap-10">
    <button type="submit" mat-raised-button color="primary" [disabled]="toolForm.pristine">{{'tool.Save' | translate}}</button>
    <button type="button" *ngIf="toolForm.controls['name'].value" mat-raised-button color="warn" (click)="deleteTool()">{{'tool.Delete' | translate}}</button>
  </div>
</form>
