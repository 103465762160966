<mat-card>
  <mat-card-content>
    <h3>{{'side.Summary' | translate}}  {{currentProfile.professional.firstName + " " + currentProfile.professional.lastName}}</h3>
    <mat-card *ngIf="errorMessage">
      <mat-card-content>
        <mat-error>{{errorMessage | translate}}</mat-error>
      </mat-card-content>
    </mat-card>
    <mat-accordion multi="true" cdkDropList (cdkDropListDropped)="dropSummary($event)">
    <mat-expansion-panel *ngFor="let summary of currentProfile.summaries; let i = index" class="cdk-cursor" cdkDrag>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <strong *ngIf="i == 0">{{summary.name}} </strong>&nbsp;<i *ngIf="i == 0"> ({{'sum.Current' | translate}})</i>
          <span *ngIf="i > 0">{{summary.name}} </span>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <ng-template matExpansionPanelContent>
        <app-summary-form (formResult)="saveSummary($event)" [currentSummary]="summary" (deleteSum)="deleteSummary($event)"></app-summary-form>
      </ng-template>
    </mat-expansion-panel>

      <mat-expansion-panel [expanded]="currentProfile.summaries.length < 1">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <strong><i>{{'sum.Add' | translate}}</i></strong>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
          <app-summary-form [currentSummary]={} (formResult)="saveSummary($event)"> </app-summary-form>
        </ng-template>
      </mat-expansion-panel>

    </mat-accordion>
    <div>
    <mat-hint *ngIf="currentProfile.summaries.length > 1" translate>sum.DragDrop</mat-hint>
    </div>
  </mat-card-content>
</mat-card>

