import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormGroupDirective, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Education} from "../../../model/education";
import {Formcontainer} from "../../../model/formcontainer";

@Component({
  selector: 'app-education-form',
  templateUrl: './education-form.component.html',
  styleUrls: ['../../../shared/styles/cv.css']
})
export class EducationFormComponent implements OnInit {
  @Input()
  currentEducation: Education;
  educationForm: UntypedFormGroup;

  @Output()
  formResult: EventEmitter<Formcontainer> = new EventEmitter();

  @Output()
  deleteEdu: EventEmitter<any> = new EventEmitter();

  educationLevels: string[] = ["MBO", "HBO", "WO", "BSC", "MSC"];

  constructor(private fb: UntypedFormBuilder) { }

  ngOnInit() {
    this.educationForm = this.fb.group({
      nameEducationNL: ['', Validators.required],
      nameEducationEN: ['', Validators.required],
      nameInstitute: ['', Validators.required],
      educationLevel: ['', Validators.required],
      startDate: ['', Validators.required],
      endDate: [''],
      graduated: [true],
      id: [null],
    });
    if (this.currentEducation != null) {
      this.educationForm.patchValue(this.currentEducation);
    }
  }

  saveEducation(form: UntypedFormGroup, formDirective: FormGroupDirective) {
    this.formResult.emit({form: form, formDirective: formDirective});
  }
  deleteEducation() {
    this.deleteEdu.emit(this.educationForm)
  }

}
