import {Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Profile} from "../../model/profile";
import {Assignment} from "../../model/assignment";
import {Observable} from 'rxjs';
import {Education} from "../../model/education";
import {Certificate} from "../../model/certificate";
import {Interest} from "../../model/interests";
import {SpokenLanguage} from "../../model/spokenLanguage";
import {Tool} from "../../model/tool";
import {SoftSkill} from "../../model/softSkill";
import {Summary} from "../../model/summary";

@Injectable({
  providedIn: 'root'
})
export class ProfileApiService {

  constructor(private http: HttpClient) {

  }

  private profileExists(profileId: string): Observable<boolean> {
    return this.http.get<boolean>(`/api/profiles/exists/${profileId}`);
  }


  getAvailableProfiles(): Observable<Profile[]> {
    return this.http.get<Profile[]>(`/api/profiles`);
  }

  getAvailableProfileIds(): Observable<string[]> {
    return this.http.get<string[]>(`/api/profiles/ids`);
  }


  getProfile(profileId): Observable<Profile> {
    return this.http.get<Profile>(`/api/profiles/${profileId}`);
  }

  createEmptyProfile(profileId: string): Observable<Profile> {
    return this.http.post<Profile>(`/api/profiles/${profileId}`, null);
  }

  updateProfile(profile: Profile): Observable<Profile> {
    let profileId = profile.id;
    return this.http.put<Profile>(`/api/profiles/${profileId}`, profile)
  }

  addSummary(profileId: string, summary: Summary): Observable<Education> {
    return this.http.post<Education>(`/api/profiles/${profileId}/summary`, summary)
  }

  deleteSummary(profileId: string, summaryId: number): Observable<{}> {
    return this.http.delete(`/api/profiles/${profileId}/summary/${summaryId}`)
  }

  addAssignment(profileId: string, assignment: Assignment): Observable<Assignment> {
    return this.http.post<Assignment>(`/api/profiles/${profileId}/assignment`, assignment)
  }

  deleteAssignment(profileId: string, assignmentId: number): Observable<{}> {
    return this.http.delete(`/api/profiles/${profileId}/assignment/${assignmentId}`)
  }

  addEducation(profileId: string, education: Education): Observable<Education> {
    return this.http.post<Education>(`/api/profiles/${profileId}/education`, education)
  }

  deleteEducation(profileId: string, educationId: number): Observable<{}> {
    return this.http.delete(`/api/profiles/${profileId}/education/${educationId}`)
  }

  addCertificate(profileId: string, certificate: Certificate): Observable<Certificate> {
    return this.http.post<Certificate>(`/api/profiles/${profileId}/certificate`, certificate)
  }

  deleteCertificate(profileId: string, certificateId: number): Observable<{}> {
    return this.http.delete(`/api/profiles/${profileId}/certificate/${certificateId}`)
  }

  addInterest(profileId: string, interest: Interest): Observable<Interest> {
    return this.http.post<Interest>(`/api/profiles/${profileId}/interest`, interest)
  }

  deleteInterest(profileId: string, interestId: number): Observable<{}> {
    return this.http.delete(`/api/profiles/${profileId}/interest/${interestId}`)
  }

  addLanguage(profileId: string, language: SpokenLanguage): Observable<SpokenLanguage> {
    return this.http.post<SpokenLanguage>(`/api/profiles/${profileId}/language`, language)
  }

  deleteLanguage(profileId: string, languageId: number): Observable<{}> {
    return this.http.delete(`/api/profiles/${profileId}/language/${languageId}`)
  }

  addTool(profileId: string, tool: Tool): Observable<Tool> {
    return this.http.post<Tool>(`/api/profiles/${profileId}/tool`, tool)
  }

  deleteTool(profileId: string, toolId: number): Observable<{}> {
    return this.http.delete(`/api/profiles/${profileId}/tool/${toolId}`)
  }

  addSoftSkill(profileId: string, softSkill: SoftSkill): Observable<SoftSkill> {
    return this.http.post<SoftSkill>(`/api/profiles/${profileId}/softSkill`, softSkill)
  }

  deleteSoftSkill(profileId: string, softSkillId: number): Observable<{}> {
    return this.http.delete(`/api/profiles/${profileId}/softSkill/${softSkillId}`)
  }

  getCurrentPhoto(profileId: string) {
    return this.http.get(`/api/profiles/photo/${profileId}`, {responseType: 'blob'})
  }

  deleteCurrentPhoto(profileId: string) {
    return this.http.delete(`/api/profiles/photo/${profileId}`)
  }

}
