import {Component, OnInit} from '@angular/core';
import {OidcSecurityService} from "angular-auth-oidc-client";
import {Observable} from "rxjs";
import { map } from 'rxjs/operators';
import {UserApiService} from "../../../core/services/user-api.service";
import {User} from "../../../model/user";

@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['../../../components/dashboard/dashboard.css']
})
export class SideBarComponent implements OnInit {
  isAuthenticated$: Observable<boolean>;
  isProfessional$: Observable<boolean>;
  isSales$: Observable<boolean>;
  currentUser$: Observable<User>

  constructor(private oidcSecurityService: OidcSecurityService, private userApiService: UserApiService) {
  }

  ngOnInit() {
    this.isAuthenticated$ = this.oidcSecurityService.isAuthenticated$.pipe(map(result => result.isAuthenticated));
    this.isProfessional$ = this.userApiService.isProfessional$();
    this.isSales$ = this.userApiService.isSales$();
    this.currentUser$ = this.userApiService.getCurrentUser();
  }

  hasSalesProfileSelected(): boolean {
    return sessionStorage.getItem('activeProfileId') != null;
  }

  resetProfile() {
    sessionStorage.removeItem('activeProfileId');
  }
}
