import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Certificate} from "../../../model/certificate";
import {FormGroupDirective, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Formcontainer} from "../../../model/formcontainer";

@Component({
  selector: 'app-certificate-form',
  templateUrl: './certificate-form.component.html',
  styleUrls: ['../../../shared/styles/cv.css']
})
export class CertificateFormComponent implements OnInit {
  @Input()
  currentCertificate: Certificate;
  certificateForm: UntypedFormGroup;
  tools: UntypedFormArray;

  @Output()
  formResult: EventEmitter<Formcontainer> = new EventEmitter();

  @Output()
  deleteCert: EventEmitter<any> = new EventEmitter();

  constructor(private fb: UntypedFormBuilder) { }


  ngOnInit() {
    this.certificateForm = this.fb.group({
      name: ['', Validators.required],
      expirationDate: [''],
      issueDate: ['', Validators.required],
      graduated: [true],
      id: [null],
    });
    if (this.currentCertificate != null) {
      this.certificateForm.patchValue(this.currentCertificate);
    }
  }

  saveCertificate(form: UntypedFormGroup, formDirective: FormGroupDirective) {
    this.formResult.emit({form: form, formDirective: formDirective});
  }
  deleteCertificate() {
    this.deleteCert.emit(this.certificateForm)
  }

}
