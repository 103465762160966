import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
  FormGroupDirective,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import {Assignment} from "../../../model/assignment";
import {ProfileApiService} from "../../../core/services/profile-api.service";
import {Formcontainer} from "../../../model/formcontainer";

@Component({
  selector: 'app-assignment-form',
  templateUrl: './assignment-form.component.html',
  styleUrls: ['../../../shared/styles/cv.css']
})
export class AssignmentFormComponent implements OnInit {
  @Input()
  currentAssignment: Assignment;
  assignmentForm: UntypedFormGroup;
  tools: UntypedFormArray;

  @Output()
  formResult: EventEmitter<Formcontainer> = new EventEmitter();

  @Output()
  deleteAssign: EventEmitter<any> = new EventEmitter();

  constructor(private api: ProfileApiService, private fb: UntypedFormBuilder) { }

  ngOnInit() {
    this.assignmentForm = this.fb.group({
      organisationNL: ['', Validators.required],
      organisationEN: ['', Validators.required],
      roleNL: ['', Validators.required],
      roleEN: ['', Validators.required],
      descriptionNL: ['', Validators.required],
      descriptionEN: ['', Validators.required],
      startDate: ['', Validators.required],
      endDate: [''],
      tools: this.fb.array([]),
      id: [null],
    });
    this.tools = this.assignmentForm.get('tools') as UntypedFormArray;
    if (this.currentAssignment != null) {
      this.assignmentForm.patchValue(this.currentAssignment);
      if (this.currentAssignment.tools) {
        this.currentAssignment.tools.forEach(t => {
          if (t) {
            this.addToolItem(t)
          }
        })
      }
    }
  }

  addItem(): void {
    this.addToolItem("")
  }
  addToolItem(value: string): void {
    if (this.tools == null) {
      this.tools = this.assignmentForm.get('tools') as UntypedFormArray;
      this.tools.clear();
    }
    this.tools.push(new UntypedFormControl(value));
  }

  deleteNewTool(index: number) {
    let tools = this.assignmentForm.get('tools') as UntypedFormArray;
    tools.removeAt(index);
  }
  saveAssignment(form: UntypedFormGroup, formDirective: FormGroupDirective) {
    this.formResult.emit({form: form, formDirective: formDirective});
  }
  deleteAssignment() {
    this.deleteAssign.emit(this.assignmentForm)
  }

}
