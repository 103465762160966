import {Component, OnInit} from '@angular/core';
import {Profile} from "../model/profile";
import {ProfileApiService} from "../core/services/profile-api.service";
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {CvService} from "./cv.service";
import {CurriculumVitaeSpecification} from "../model/curriculumVitaeSpecification";
import {UserApiService} from "../core/services/user-api.service";
import {User} from "../model/user";
import {MatSnackBar} from "@angular/material/snack-bar";
import {ActivatedRoute} from "@angular/router";
import {Role} from "../model/role";


@Component({
  selector: 'app-download',
  templateUrl: './download.component.html',
  styleUrls: ['./download.component.css']
})
export class DownloadComponent implements OnInit {
  errorMessage: string;
  currentProfile: Profile;
  cvForm: UntypedFormGroup;
  availableUsers: User[] = [];
  currentUser: User;
  selectedUser: User;
  availableProfileIds: string[] = [];
  generating = false;

  languages: string[] = [ "DUTCH", "ENGLISH" ]
  templateNames: string[] = [ "GROUP9", "HAYSTAQ", "MSTACK", "BRIGHTR", "ALFAONE", "BLUPOINT", "ADAPTIF", "N_ABLED", "MERAPAR" ]
  isSales: boolean;

  constructor(private route: ActivatedRoute, private api: ProfileApiService, private fb: UntypedFormBuilder, private cvs: CvService, private userApi: UserApiService, private _snackBar: MatSnackBar) {
    this.currentProfile = route.snapshot.data.currentProfile;
    this.currentUser = route.snapshot.data.currentUser;
    this.isSales = this.currentUser.roles.indexOf(Role.SALES) > -1;
  }

  ngOnInit() {
    this.cvForm = this.fb.group({
      profileId: ['', Validators.required],
      language: ['DUTCH', Validators.required],
      templateName: ['', Validators.required],
      usePhoto: [true],
      //maxLanguages: [1, Validators.required],
      //maxLanguagesSummary: [1, Validators.required],
      maxEducation: [1, Validators.required],
      //maxEducationSummary: [1, Validators.required],
      maxCertificates: [1, Validators.required],
      //maxCertificatesSummary: [1, Validators.required],
      //maxPersonalInterest: [1, Validators.required],
      maxAssignmentsDetail: [1, Validators.required],
      maxAssignmentsSummary: [1, Validators.required],
      // maxSideActivities: [5, Validators.required],
      //maxTools: [1, Validators.required],
      selectAssignments:[[]],
      summaryId: ['', Validators.required]
    });
    // this.getCurrentProfile()
    this.setValuesAfterProfileChange();

    this.getAvailableProfiles();
  }

  getProfile(id: string): void {
    this.api.getProfile(id)
      .subscribe({
        next: (profile: Profile) => {
          this.currentProfile = profile
          this.setValuesAfterProfileChange();
        },
        error: err => this.errorMessage = err.messsage
      });
  }

  getAvailableProfiles(): void {
    if (this.isSales) {
      this.api.getAvailableProfileIds()
        .subscribe({
          next: (availableProfileIds: string[]) => {
            this.availableProfileIds = availableProfileIds;
            this.userApi.getAllUsers()
              .subscribe({
                next: (users: User[]) => {
                  users = users.filter(u => this.availableProfileIds.indexOf(u.profileId) > -1);
                  this.availableUsers = users;
                  this.selectUserWatch();
                  this.availableUsers.sort((a, b) => a.name.localeCompare(b.name));
                }
              });
          }
        });
    } else {
      this.availableUsers.push(this.currentUser);
      this.cvForm.get('profileId').setValue(this.currentUser.profileId);
      this.selectUserWatch();
    }
  }

  selectUserWatch() {
    this.selectedUser = this.availableUsers.find(u => u.profileId == this.cvForm.get('profileId').value);
    this.cvForm.get('profileId').valueChanges.subscribe(value => {
      this.selectedUser = this.availableUsers.find(u => u.profileId == this.cvForm.get('profileId').value);
      this.getProfile(this.selectedUser.profileId)
    });
  }


  generateCV() {
    this.errorMessage = null;
    this.generating = true;
    const cv: CurriculumVitaeSpecification = {...this.cvForm.value}
    cv.company = cv.templateName;
    return this.cvs.generateCV(cv).subscribe({
      next: x => {
        // It is necessary to create a new blob object with mime-type explicitly set
        // otherwise only Chrome works like it should
        var newBlob = new Blob([x], {type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document"});

        // IE doesn't allow using a blob object directly as link href
        // instead it is necessary to use msSaveOrOpenBlob
        // if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        //   window.navigator.msSaveOrOpenBlob(newBlob);
        //   return;
        // }

        // For other browsers:
        // Create a link pointing to the ObjectURL containing the blob.
        const data = window.URL.createObjectURL(newBlob);

        var link = document.createElement('a');
        link.href = data;
        link.download = "CV " + this.selectedUser.name + "-" + this.getCurrentTimestamp() + ".docx";
        // this is necessary as link.click() does not work on the latest firefox
        link.dispatchEvent(new MouseEvent('click', {bubbles: true, cancelable: true, view: window}));

        setTimeout(function () {
          // For Firefox it is necessary to delay revoking the ObjectURL
          window.URL.revokeObjectURL(data);
          link.remove();
        }, 100);

        this.generating = false;
      },
      error: err => {
        console.log(err)
        this.errorMessage = "Er is helaas iets foutgegaan met het downloaden van het CV. Neem contact op met de beheerder. " + err.message;
        this.generating = false;
      }
    });
  }
  setValuesAfterProfileChange() {
    let selectAssignments: number[] = [];

    this.cvForm.get('templateName').patchValue(this.currentProfile.professional.company);

    //this.cvForm.get('maxLanguages').patchValue(this.currentProfile.languages.length);
    //this.cvForm.get('maxLanguagesSummary').patchValue(this.currentProfile.languages.length);
    this.cvForm.get('maxEducation').patchValue(this.currentProfile.educations.length);
    //this.cvForm.get('maxEducationSummary').patchValue(this.currentProfile.educations.length);
    this.cvForm.get('maxCertificates').patchValue(this.currentProfile.certificates.length);
    //this.cvForm.get('maxCertificatesSummary').patchValue(this.currentProfile.certificates.length);
    //this.cvForm.get('maxPersonalInterest').patchValue(this.currentProfile.interests.length);
    this.cvForm.get('maxAssignmentsDetail').patchValue(this.currentProfile.assignments.length > 5 ? 5 : this.currentProfile.assignments.length);
    this.cvForm.get('maxAssignmentsSummary').patchValue(this.currentProfile.assignments.length);
    //this.cvForm.get('maxTools').patchValue(this.currentProfile.tools.length);

    this.cvForm.get('usePhoto').patchValue(this.currentProfile.professional.usePhoto);
    if (this.currentProfile.professional.usePhoto) {
      this.cvForm.get('usePhoto').enable();
    } else {
      this.cvForm.get('usePhoto').disable();
    }

    this.currentProfile.assignments.forEach(a => selectAssignments.push(a.id));
    this.cvForm.get('selectAssignments').patchValue(selectAssignments);
    if (this.currentProfile.summaries.length > 0) {
      this.cvForm.get('summaryId').patchValue(this.currentProfile.summaries[0].id);
    }
    if (this.isSales) {
      sessionStorage.setItem('activeProfileId', this.currentProfile.id);
    }

    this._snackBar.open("Bewerken van profiel: " + this.currentProfile.professional.firstName + " " + this.currentProfile.professional.lastName, "", {
      duration: 5000,
    });

  }

  getCurrentTimestamp(): string {
    let date = new Date();
    let year = date.getFullYear();
    let month = (date.getMonth() + 1).toString().padStart(2, '0');
    let day = date.getDate().toString().padStart(2, '0');
    let hours = date.getHours().toString().padStart(2, '0');
    let minutes = date.getMinutes().toString().padStart(2, '0');
    return `${year}${month}${day}-${hours}${minutes}`;
  }
}
