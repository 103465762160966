<mat-card>
  <mat-card-content>
    <div>
      <span *ngFor="let filterItem of filterItems; let i = index;">
        <mat-checkbox [(ngModel)]="filterItem.checked" style="margin-right: 1em;"
                      (change)="filterProfiles()">{{"cv." + filterItem.value | translate}}</mat-checkbox>
      </span>
      <mat-expansion-panel style="margin-top: 1em;">
        <mat-expansion-panel-header>
          <mat-panel-title translate>Filter werkgroepen</mat-panel-title>
        </mat-expansion-panel-header>
        <div>
          <span *ngFor="let group of filterGroups; let i = index;">
            <mat-checkbox [(ngModel)]="group.checked" style="margin-right: 1em;" (change)="filterProfiles()">{{group.value}}</mat-checkbox>
          </span>
        </div>
      </mat-expansion-panel>
    </div>
    <mat-divider></mat-divider>
    <br/>
    <div class="d-flex flex-wrap gap-20 flex-fill">
      <mat-card class="facebook-card" *ngFor="let profile of filteredProfiles">
        <mat-card-header>
          <img mat-card-avatar src="/assets/images/facebook/{{profile.company | lowercase}}/logo.png"/>
          <mat-card-title>{{profile.name}}</mat-card-title>
          <mat-card-subtitle *ngIf="profile.address">{{profile.address}}</mat-card-subtitle>
          <mat-card-subtitle>{{profile.zipcode ? profile.zipcode + ', ' : ''}}{{profile.placeOfResidence ? profile.placeOfResidence : "Onbekend" }}</mat-card-subtitle>
          <mat-card-subtitle *ngIf="profile.partner">Partner: {{profile.partner}}</mat-card-subtitle>
          <mat-card-subtitle *ngIf="profile.children">Kind(eren): {{profile.children}}</mat-card-subtitle>
        </mat-card-header>
        <div class="img-container">
          <img *ngIf="profile.photoUrl != null" mat-card-image [src]="profile.photoUrl">
        </div>
        <mat-card-actions>
          <div>
            <span *ngIf="profile.emailAddress"> E: <a href="mailto:{{profile.emailAddress}}"
                                                      target="_blank">{{profile.emailAddress}}</a> <br/></span>
            <span *ngIf="profile.phoneNumber">T: {{profile.phoneNumber}} <br/></span>
            <span *ngIf="profile.currentAssignment">Huidige opdracht: {{profile.currentAssignment}} <br/></span>
            <span *ngIf="profile.groups.length > 0">Lid van: <span
              *ngFor="let group of profile.groups; let i = index; ">{{group.trim()}}{{ profile.groups.length > i + 1 ? ', ' : '' }}</span>
            </span>
          </div>
        </mat-card-actions>
      </mat-card>
    </div>
  </mat-card-content>
</mat-card>

