import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormGroupDirective, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Proficiency, SpokenLanguage} from "../../../model/spokenLanguage";
import {Formcontainer} from "../../../model/formcontainer";

@Component({
  selector: 'app-language-form',
  templateUrl: './language-form.component.html',
  styleUrls: ['../../../shared/styles/cv.css']
})
export class LanguageFormComponent implements OnInit {

  @Input()
  currentLanguage: SpokenLanguage;
  languageForm: UntypedFormGroup;

  proficiencies: Proficiency[] = Object.keys(Proficiency).map(key => Proficiency[key]);

  @Output()
  formResult: EventEmitter<Formcontainer> = new EventEmitter();

  @Output()
  deleteLang: EventEmitter<any> = new EventEmitter();

  constructor(private fb: UntypedFormBuilder) { }

  ngOnInit() {
    this.languageForm = this.fb.group({
      nameNL: ['', Validators.required],
      nameEN: ['', Validators.required],
      proficiency: [''],
      id: [null],
    });
    if (this.currentLanguage != null) {
      this.languageForm.patchValue(this.currentLanguage);
    }
  }

  saveLanguage(form: UntypedFormGroup, formDirective: FormGroupDirective) {
    this.formResult.emit({form: form, formDirective: formDirective});
  }
  deleteLanguage() {
    this.deleteLang.emit(this.languageForm)
  }

}
