<form class="d-flex flex-column gap-10 max-w-50p-on-pc" novalidate [formGroup]="assignmentForm" (ngSubmit)="saveAssignment(assignmentForm, formDirective)" #formDirective="ngForm">
  <div class="d-flex flex-row gap-20">
    <mat-form-field class="flex">
      <mat-label>{{ 'ass.CompanyName' | translate }}</mat-label>
      <input matInput formControlName="organisationNL">
      <mat-hint translate>NL</mat-hint>
    </mat-form-field>
    <mat-form-field class="flex">
      <mat-label>{{ 'ass.CompanyName' | translate }}</mat-label>
      <input matInput formControlName="organisationEN">
      <mat-hint translate>EN</mat-hint>
    </mat-form-field>
  </div>

  <div class="d-flex flex-row gap-20">
    <mat-form-field class="flex">
      <mat-label>{{ 'ass.Role' | translate }}</mat-label>
      <input matInput formControlName="roleNL">
      <mat-hint translate>NL</mat-hint>
    </mat-form-field>
    <mat-form-field class="flex">
      <mat-label>{{ 'ass.Role' | translate }}</mat-label>
      <input matInput formControlName="roleEN">
      <mat-hint translate>EN</mat-hint>
    </mat-form-field>
  </div>

  <div class="d-flex flex-row gap-20">
    <mat-form-field class="flex">
      <mat-label>{{ 'Description' | translate }}</mat-label>
      <textarea matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="5" maxlength="2000" style="overflow: hidden;"
                formControlName="descriptionNL"></textarea>
      <mat-hint translate>NL</mat-hint>
    </mat-form-field>
    <mat-form-field class="flex">
      <mat-label>{{ 'Description' | translate }}</mat-label>
      <textarea matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="5" maxlength="2000" style="overflow: hidden;"
                formControlName="descriptionEN"></textarea>
      <mat-hint translate>EN</mat-hint>
    </mat-form-field>
  </div>

  <div class="d-flex flex-row gap-20">
    <mat-form-field class="flex">
      <mat-label>{{ 'StartDate' | translate }}</mat-label>
      <input matInput [matDatepicker]="startDate" formControlName="startDate">
      <mat-datepicker-toggle matIconSuffix [for]="startDate"></mat-datepicker-toggle>
      <mat-datepicker #startDate startView="multi-year"></mat-datepicker>
    </mat-form-field>

    <mat-form-field class="flex">
      <mat-label>{{ 'EndDate' | translate }}</mat-label>
      <input matInput [matDatepicker]="endDate" formControlName="endDate">
      <mat-datepicker-toggle matIconSuffix [for]="endDate"></mat-datepicker-toggle>
      <mat-datepicker #endDate startView="multi-year"></mat-datepicker>
      <mat-hint translate>EmptyCurrent</mat-hint>
    </mat-form-field>
  </div>

  <div class="d-flex gap-20" formArrayName="tools" *ngFor="let item of assignmentForm.get('tools')['controls']; let i = index;">
    <mat-form-field>
      <mat-label>{{ i==0 ? ('Tools' | translate ) : ''}}</mat-label>
      <input matInput formControlName="{{i}}" (keyup.enter)="addItem()">
    </mat-form-field>
    <div style="margin-top: 5px;">
      <button type="button" mat-raised-button color="accent"
              (click)="deleteNewTool(i)">{{'Delete' | translate}}</button>
    </div>
  </div>
  <div>
    <button type="button" mat-raised-button color="secondary" (click)="addItem()">{{'ass.AddTool' | translate}}</button>
  </div>

  <div class="d-flex gap-10">
    <button type="submit" mat-raised-button color="primary"
            [disabled]="assignmentForm.pristine">{{'ass.Save' | translate}}</button>
    <button type="button" *ngIf="assignmentForm.controls['id'].value" mat-raised-button color="warn"
            (click)="deleteAssignment()">{{'ass.Delete' | translate}}</button>
  </div>
</form>
