<mat-card>
  <mat-card-content>
    <h3>{{'side.Tools' | translate}}  {{currentProfile.professional.firstName + " " + currentProfile.professional.lastName}}</h3>
    <mat-card *ngIf="errorMessage">
      <mat-card-content>
        <mat-error>{{errorMessage | translate}}</mat-error>
      </mat-card-content>
    </mat-card>

    <mat-accordion multi="true" cdkDropList (cdkDropListDropped)="dropTool($event)">
      <mat-expansion-panel expanded="{{currentProfile.tools.length < 2}}">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <strong>{{'tool.Add' | translate}}</strong>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
          <app-tool-form [currentTool]="" (formResult)="saveTool($event)"> </app-tool-form>
        </ng-template>
      </mat-expansion-panel>

      <mat-expansion-panel *ngFor="let tool of currentProfile.tools" class="cdk-cursor" cdkDrag>
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{"tool." + tool.toolType | translate}} - {{tool.name}}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
          <app-tool-form [currentTool]="tool" (formResult)="saveTool($event)" (deleteTl)="deleteTool($event)"> </app-tool-form>
        </ng-template>
      </mat-expansion-panel>
    </mat-accordion>
    <div class="drag-drop-text-container">
      <mat-hint *ngIf="currentProfile.tools.length > 1" translate>DragDrop</mat-hint>
    </div>
  </mat-card-content>
</mat-card>
