<form class="d-flex flex-column max-w-50p-on-pc" novalidate [formGroup]="interestForm"(ngSubmit)="saveInterest(interestForm, formDirective)" #formDirective="ngForm">
  <div class="d-flex gap-20">
    <mat-form-field class="flex">
      <mat-label>{{ 'int.Interest' | translate }}</mat-label>
      <input matInput formControlName="interestNL">
      <mat-hint translate>NL</mat-hint>
    </mat-form-field>
    <mat-form-field class="flex">
      <mat-label>{{ 'int.Interest' | translate }}</mat-label>
      <input matInput formControlName="interestEN">
      <mat-hint translate>EN</mat-hint>
    </mat-form-field>
  </div>
  <br />
  <div class="d-flex gap-10">
    <button type="submit" mat-raised-button color="primary" [disabled]="interestForm.pristine">{{'int.Save' | translate}}</button>
    <button type="button" *ngIf="interestForm.controls['id'].value" mat-raised-button color="warn" (click)="deleteInterest()">{{'int.Delete' | translate}}</button>
  </div>
</form>
