import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormGroupDirective, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {SoftSkill, SoftSkillLevel} from "../../../model/softSkill";
import {Formcontainer} from "../../../model/formcontainer";

@Component({
  selector: 'app-soft-skill-form',
  templateUrl: './soft-skill-form.component.html',
  styleUrls: ['../../../shared/styles/cv.css']
})
export class SoftSkillFormComponent implements OnInit {
  @Input()
  currentSoftSkill: SoftSkill;
  softSkillForm: UntypedFormGroup;
  softSkills: UntypedFormArray;

  @Output()
  formResult: EventEmitter<Formcontainer> = new EventEmitter();

  @Output()
  deleteTl: EventEmitter<any> = new EventEmitter();

  levels: SoftSkillLevel[] = Object.keys(SoftSkillLevel).map(key => SoftSkillLevel[key]);

  constructor(private fb: UntypedFormBuilder) { }


  ngOnInit() {
    this.softSkillForm = this.fb.group({
      nameSoftSkillNL: ['', Validators.required],
      nameSoftSkillEN: ['', Validators.required],
      level: ['', Validators.required],
      id: [null]
    });
    if (this.currentSoftSkill != null) {
      console.log(this.currentSoftSkill)
      this.softSkillForm.patchValue(this.currentSoftSkill);
    }
  }

  saveSoftSkill(form: UntypedFormGroup, formDirective: FormGroupDirective) {
    this.formResult.emit({form: form, formDirective: formDirective});
  }
  deleteSoftSkill() {
    this.deleteTl.emit(this.softSkillForm)
  }

}
