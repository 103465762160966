import {Injectable} from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {CurriculumVitaeSpecification} from '../model/curriculumVitaeSpecification';

@Injectable({
  providedIn: 'root'
})
export class CvService {

  constructor(private http: HttpClient) {  }

   generateCV(cv: CurriculumVitaeSpecification) {
    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document');
    return this.http.post(`/api/cv`, cv, { headers: headers, responseType: 'blob'});
  }
}
