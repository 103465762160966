import {Component, OnInit} from '@angular/core';
import {Profile} from '../../../model/profile';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {ProfileApiService} from "../../../core/services/profile-api.service";
import {ActivatedRoute} from '@angular/router';
import {CdkDragDrop, moveItemInArray} from "@angular/cdk/drag-drop";
import {Summary} from "../../../model/summary";
import {Formcontainer} from "../../../model/formcontainer";

@Component({
  selector: 'app-profile',
  templateUrl: './summary.component.html',
  styleUrls: ['../../../shared/styles/cv.css']
})
export class SummaryComponent implements OnInit {
  errorMessage: string;
  currentProfile: Profile;

  constructor(private route: ActivatedRoute, private fb: UntypedFormBuilder, private api: ProfileApiService) {
    this.currentProfile = route.snapshot.data.currentProfile;
  }

  ngOnInit() {
  }

  dropSummary(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.currentProfile.summaries, event.previousIndex, event.currentIndex);
    this.saveProfile();
  }

  saveSummary(form: Formcontainer) {
    if (form.form.valid) {
      if (form.form.dirty) {
        const s: Summary = { ...form.form.value};
        this.api.addSummary(this.currentProfile.id, s).subscribe({
          next: () => this.onSaveComplete(form),
          error: err => this.errorMessage = err.messsage
        });
      } else {
        this.onSaveComplete(form);
      }
    } else {
      this.errorMessage = 'correct.values';
    }
  }

  deleteSummary(summaryForm: UntypedFormGroup) {
    if(window.confirm('Are sure you want to delete this item ?')) {
      this.api.deleteSummary(this.currentProfile.id, summaryForm.controls['id'].value).subscribe(res => {
        this.api.getProfile(this.currentProfile.id).subscribe( res => this.currentProfile = res)
      })
    }
  }



  onSaveComplete(form: Formcontainer): void {
    // Reset the form to clear the flags
    if (form.form.controls['id'].value == null) {
      form.form.reset();
      form.formDirective.resetForm()
    }
    this.api.getProfile(this.currentProfile.id).subscribe( res => this.currentProfile = res)
  }
  saveProfile() {
    this.api.updateProfile(this.currentProfile).subscribe();
  }

}
