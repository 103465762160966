<form class="d-flex flex-column max-w-50p-on-pc" novalidate [formGroup]="languageForm" (ngSubmit)="saveLanguage(languageForm, formDirective)" #formDirective="ngForm">
  <div class="d-flex gap-20">
    <mat-form-field class="flex">
      <mat-label>{{ 'lang.Name' | translate }}</mat-label>
      <input matInput formControlName="nameNL">
      <mat-hint translate>NL</mat-hint>
    </mat-form-field>
    <mat-form-field class="flex">
      <mat-label>{{ 'lang.Name' | translate }}</mat-label>
      <input matInput formControlName="nameEN">
      <mat-hint translate>EN</mat-hint>
    </mat-form-field>
  </div>
  <mat-form-field class="flex">
    <mat-label translate>lang.Proficiency</mat-label>
    <mat-select formControlName="proficiency">
      <mat-option *ngFor="let proficiency of proficiencies" [value]="proficiency">
        {{proficiency | translate}}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <br />
  <div class="d-flex gap-10">
    <button type="submit" mat-raised-button color="primary" [disabled]="languageForm.pristine">{{'lang.Save' | translate}}</button>
    <button type="button" *ngIf="languageForm.controls['id'].value" mat-raised-button color="warn" (click)="deleteLanguage()">{{'lang.Delete' | translate}}</button>
  </div>
</form>
