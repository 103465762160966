export class Tool {
  id: number;
  name: string;
  level: ToolLevel;
  toolType: ToolType
}

export enum ToolType {
  ROLE = 'ROLE',
  METHOD_TECHNIQUE = 'METHOD_TECHNIQUE',
  TOOL_APPLICATION = 'TOOL_APPLICATION',
  DEV_LANGUAGE = 'DEV_LANGUAGE',
  FRAMEWORK = 'FRAMEWORK',
  FRONTEND = 'FRONTEND',
  TESTING = 'TESTING',
  DEV_OPS = 'DEV_OPS',
  DATABASE = 'DATABASE',
  CLOUD = 'CLOUD'
}

export enum ToolLevel {
  NOT_APPLICABLE = 'NOT_APPLICABLE',
  BASIC = 'BASIC',
  COMPETENT = 'COMPETENT',
  ADVANCED = 'ADVANCED',
  EXPERT = 'EXPERT'
}

