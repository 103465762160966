<mat-card appearance="raised">
  <mat-card-content>
    <h1 translate>dash.Welcome</h1>
  </mat-card-content>
</mat-card>

<mat-card appearance="raised" *ngIf="(isAuthenticated$ | async)">
  <mat-card-header>
    <h4 translate>dash.ContinueCV</h4>
  </mat-card-header>
  <mat-card-content>
    <a mat-raised-button color="primary" [routerLink]="['/personal-details']" >
      {{'dash.Continue'| translate  }}
    </a>
  </mat-card-content>
</mat-card>

