import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormGroupDirective, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Summary} from "../../../model/summary";
import {Formcontainer} from "../../../model/formcontainer";

@Component({
  selector: 'app-summary-form',
  templateUrl: './summary-form.component.html',
  styleUrls: ['../../../shared/styles/cv.css']
})
export class SummaryFormComponent implements OnInit {

  @Input()
  currentSummary: Summary;
  summaryForm: UntypedFormGroup;

  @Output()
  formResult: EventEmitter<Formcontainer> = new EventEmitter();

  @Output()
  deleteSum: EventEmitter<any> = new EventEmitter();

  constructor(private fb: UntypedFormBuilder) { }

  ngOnInit() {
    this.summaryForm = this.fb.group({
      id: [null],
      name: ['', Validators.required],
      summaryNL: ['', Validators.required],
      summaryEN: ['', Validators.required]
    });
    if (this.currentSummary != null) {
      this.summaryForm.patchValue(this.currentSummary);
    }
  }

  saveSummary(form: UntypedFormGroup, formDirective: FormGroupDirective) {
    this.formResult.emit({form: form, formDirective: formDirective});
  }

  deleteSummary() {
    this.deleteSum.emit(this.summaryForm)
  }


}
