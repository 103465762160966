<mat-card>
  <mat-card-content>
    <h3>{{'side.Assignments' | translate}}  {{currentProfile.professional.firstName + " " + currentProfile.professional.lastName}}</h3>

    <mat-card *ngIf="errorMessage">
      <mat-error>{{errorMessage | translate}}</mat-error>
    </mat-card>

    <mat-accordion multi="true" cdkDropList (cdkDropListDropped)="dropAssignment($event)">
      <mat-expansion-panel expanded="{{currentProfile.assignments.length < 2}}">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <strong>{{'ass.Add' | translate}}</strong>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
          <app-assignment-form [currentAssignment]={} (formResult)="saveAssignment($event)"> </app-assignment-form>
        </ng-template>
      </mat-expansion-panel>

      <mat-expansion-panel *ngFor="let assignment of currentProfile.assignments" class="cdk-cursor" cdkDrag>
        <mat-expansion-panel-header>
          <mat-panel-title class="flex-grow-2-on-mobile-otherwise-1">
            {{assignment.organisationNL}} - {{assignment.roleNL}}
          </mat-panel-title>
          <mat-panel-description>
            {{assignment.startDate}} - {{assignment.endDate != null ? assignment.endDate : ('Current' | translate)}}
          </mat-panel-description>
        </mat-expansion-panel-header>
            <ng-template matExpansionPanelContent>
              <app-assignment-form [currentAssignment]="assignment" (formResult)="saveAssignment($event)" (deleteAssign)="deleteAssignment($event)"> </app-assignment-form>
            </ng-template>
      </mat-expansion-panel>
    </mat-accordion>
    <div class="drag-drop-text-container">
      <mat-hint *ngIf="currentProfile.assignments.length > 1" translate>DragDrop</mat-hint>
    </div>
  </mat-card-content>
</mat-card>
