import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
  FormGroupDirective,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import {Professional} from "../../../model/professional";
import {Group} from "../../../model/group";
import {Formcontainer} from "../../../model/formcontainer";

@Component({
  selector: 'app-personal-details-form',
  templateUrl: './personal-details-form.component.html',
  styleUrls: ['../../../shared/styles/cv.css']
})
export class PersonalDetailsFormComponent implements OnInit {

  @Input()
  currentPersonalDetails: Professional;
  personalDetailsForm: UntypedFormGroup;

  @Input()
  availableGroups: Group[];
  groups: UntypedFormArray;

  @Output()
  formResult: EventEmitter<Formcontainer> = new EventEmitter();

  constructor(private fb: UntypedFormBuilder) { }

  ngOnInit() {
    this.personalDetailsForm = this.fb.group({
      firstName: ['', [Validators.required, Validators.minLength(2)]],
      lastName: ['', [Validators.required, Validators.minLength(2)]],
      title: ['', Validators.required],
      dateOfBirth: ['', Validators.required],
      placeOfResidence: ['', Validators.required],
      phoneNumber: [''],
      emailAddress: [''],
      address: [''],
      zipcode: [''],
      dietaryRequirements:[''],
      partner: [''],
      children: [''],
      usePhoto: [''],
      groups: this.fb.array([new UntypedFormControl('')]),
    });
    if (this.currentPersonalDetails != null) {
      this.personalDetailsForm.patchValue(this.currentPersonalDetails);
    }
    this.availableGroups.forEach(t => {
      if (t) {
        this.addGroupItem(t.name)
      }
    })
  }


  addGroupItem(value: string): void {
    if (this.groups == null) {
      this.groups = this.personalDetailsForm.get('groups') as UntypedFormArray;
      this.groups.clear();
    }
    let selected = false;
    if (this.currentPersonalDetails.groups != null) {
      selected = this.currentPersonalDetails.groups.indexOf(value) !== -1;
    }
    this.groups.push(new UntypedFormControl(selected));
  }

  savePersonalDetails(form: UntypedFormGroup, formDirective: FormGroupDirective) {
    this.personalDetailsForm.validator
    this.formResult.emit({form: form, formDirective: formDirective});
  }

}
