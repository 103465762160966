<!--<div class="container">-->
<!--  <div class="row">-->
<!--    <div class="col-md-6 offset-md-3">-->
<!--      <h3>Choose File</h3>-->
<!--      <div class="form-group">-->
<!--        <input type="file" name="image" (change)="fileProgress($event)" />-->
<!--      </div>-->
<!--      <div *ngIf="fileUploadProgress">-->
<!--        Upload progress: {{ fileUploadProgress }}-->
<!--      </div>-->
<!--      <div class="image-preview mb-3" *ngIf="previewUrl">-->
<!--        <img [src]="previewUrl" height="300" />-->
<!--      </div>-->


<!--      <div class="mb-3" *ngIf="uploadedFilePath">-->
<!--        {{uploadedFilePath}}-->
<!--      </div>-->

<!--      <div class="form-group">-->
<!--        <button class="btn btn-primary"  (click)="onSubmit()">Submit</button>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->



<mat-card>
  <mat-card-content>
    <h3>{{'side.Photo' | translate}}  {{currentProfile.professional.firstName + " " + currentProfile.professional.lastName}}</h3>

    <mat-card>
      <mat-card-content>
        <img *ngIf="currentProfile.professional.photoUrl != null && !isImageLoading; else noImageFound" mat-card-image [src]="currentProfile.professional.photoUrl" alt="Place image title" width="200px">

        <ng-template #noImageFound>
          <img src="favicon.ico" alt="Fallbackimage">
        </ng-template>
        <br />
        <br />
        <button *ngIf="!isImageLoading" type="button" mat-raised-button color="warn" (click)="deletePhoto()">{{'Delete' | translate}}</button>
      </mat-card-content>
    </mat-card>

    <mat-card>
      <mat-card-content>
        <h3>Select files</h3>
        <input type="file" ng2FileSelect [uploader]="uploader" />
      </mat-card-content>
    </mat-card>

    <mat-card *ngIf="errorMessage">
      <mat-card-content>
        <mat-error class="error">{{errorMessage}}</mat-error>
      </mat-card-content>
    </mat-card>

    <mat-card>
      <mat-card-content>
        <h3>Upload queue</h3>
        <p>Queue length: {{ uploader?.queue?.length }}</p>

        <table class="table">
          <thead>
          <tr>
            <th width="50%">Name</th>
            <th>Size</th>
            <th>Progress</th>
            <th>Status</th>
          </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of uploader.queue">
              <td><strong>{{ item?.file?.name }}</strong></td>
              <td *ngIf="uploader.options.isHTML5" nowrap>{{ item?.file?.size/1024/1024 | number:'.2' }} MB</td>
              <td *ngIf="uploader.options.isHTML5">
                <div class="progress" style="margin-bottom: 0;">
                  <div class="progress-bar" role="progressbar" [ngStyle]="{ 'width': item.progress + '%' }"></div>
                </div>
              </td>
              <td class="text-center">
                <span *ngIf="item.isSuccess">OK</span>
                <span *ngIf="item.isCancel">Cancelled</span>
                <span *ngIf="item.isError">ERROR</span>

              </td>
            </tr>
          </tbody>
        </table>
      </mat-card-content>
    </mat-card>

    <div class="row">
      <div class="col-md-12">
        <div class="panel panel-default">
          <div class="panel-heading">Response</div>
          <div class="panel-body">
            {{ response }}
          </div>
        </div>
      </div>
    </div>
  <!--</div>-->
  </mat-card-content>
</mat-card>

