import {Component, OnInit} from '@angular/core';
import {Profile} from '../../../model/profile';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {ProfileApiService} from "../../../core/services/profile-api.service";
import {ActivatedRoute} from '@angular/router';
import {Tool} from "../../../model/tool";
import {Formcontainer} from "../../../model/formcontainer";

@Component({
  selector: 'app-tools',
  templateUrl: './tools.component.html',
  styleUrls: ['../../../shared/styles/cv.css','../../../shared/styles/cdk-drag.css']
})
export class ToolsComponent implements OnInit {
  errorMessage: string;
  currentProfile: Profile;

  constructor(private route: ActivatedRoute, private fb: UntypedFormBuilder, private api: ProfileApiService) {
    this.currentProfile = route.snapshot.data.currentProfile;

  }

  ngOnInit() {
    this.loadProfile()
  }

  loadProfile() {
    if (this.currentProfile.tools == null) {
      this.currentProfile.tools = [];
    }
  }

  saveTool(form: Formcontainer) {
    if (form.form.valid) {
      if (form.form.dirty) {
        const e: Tool = {...form.form.value}
        this.api.addTool(this.currentProfile.id, e).subscribe({
          next: () => this.onSaveComplete(form),
          error: err => this.errorMessage = err.messsage
        });
      } else {
        this.onSaveComplete(form);
      }
    } else {
      this.errorMessage = 'correct.values';
    }
  }

  deleteTool(toolForm: UntypedFormGroup) {
    if(window.confirm('Are sure you want to delete this item ?')) {
      this.api.deleteTool(this.currentProfile.id, toolForm.controls['id'].value).subscribe(res => {
        this.api.getProfile(this.currentProfile.id).subscribe( res => this.currentProfile = res)
      })
    }
  }

  onSaveComplete(form: Formcontainer): void {
    // Reset the form to clear the flags
    if (form.form.controls['id'].value == null) {
      form.form.reset();
      form.formDirective.resetForm()
    }
    this.api.getProfile(this.currentProfile.id).subscribe( res => this.currentProfile = res)
    this.loadProfile()
  }

  dropTool(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.currentProfile.tools, event.previousIndex, event.currentIndex);
    this.saveProfile();
  }
  saveProfile() {
    this.api.updateProfile(this.currentProfile).subscribe();
  }
}
