<form class="d-flex flex-column gap-10 max-w-50p-on-pc" novalidate [formGroup]="educationForm" (ngSubmit)="saveEducation(educationForm, formDirective)" #formDirective="ngForm">
  <div class="d-flex gap-20">
    <mat-form-field class="flex">
      <mat-label>{{ 'edu.NameInstitute' | translate }}</mat-label>
      <input matInput formControlName="nameInstitute">
    </mat-form-field>
    <mat-form-field class="flex">
      <mat-label translate>edu.EducationLevel</mat-label>
      <mat-select formControlName="educationLevel">
        <mat-option *ngFor="let educationLevel of educationLevels" [value]="educationLevel">
          {{"edu." + educationLevel | translate}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="d-flex gap-20">
    <mat-form-field class="flex">
      <mat-label>{{ 'edu.NameEducation' | translate }}</mat-label>
      <input matInput formControlName="nameEducationNL">
      <mat-hint translate>NL</mat-hint>
    </mat-form-field>
    <mat-form-field class="flex">
      <mat-label>{{ 'edu.NameEducation' | translate }}</mat-label>
      <input matInput formControlName="nameEducationEN">
      <mat-hint translate>EN</mat-hint>
    </mat-form-field>
  </div>

  <div class="d-flex gap-20">
    <mat-form-field class="flex">
      <mat-label>{{ 'StartDate' | translate }}</mat-label>
      <input matInput [matDatepicker]="startDate" formControlName="startDate">
      <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
      <mat-datepicker #startDate startView="multi-year"></mat-datepicker>
    </mat-form-field>
    <mat-form-field class="flex">
      <mat-label>{{ 'EndDate' | translate }}</mat-label>
      <input matInput [matDatepicker]="endDate" formControlName="endDate">
      <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
      <mat-datepicker #endDate startView="multi-year"></mat-datepicker>
      <mat-hint translate>EmptyCurrent</mat-hint>
    </mat-form-field>
  </div>
  <mat-checkbox formControlName="graduated">{{ (educationForm.get('graduated').value ? 'edu.Graduated' : 'edu.NotGraduated') | translate }}</mat-checkbox>
  <br/>
  <div class="d-flex gap-10">
    <button type="submit"  mat-raised-button color="primary" [disabled]="educationForm.pristine">{{'edu.Save' | translate}}</button>
    <button type="button" *ngIf="educationForm.controls['id'].value" mat-raised-button color="warn" (click)="deleteEducation()">{{'edu.Delete' | translate}}</button>
  </div>
</form>
