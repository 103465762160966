<mat-card>
  <mat-card-content>
    <h3>{{'side.Education' | translate}}  {{currentProfile.professional.firstName + " " + currentProfile.professional.lastName}}</h3>
    <mat-card *ngIf="errorMessage">
      <mat-card-content>
        <mat-error>{{errorMessage | translate}}</mat-error>
      </mat-card-content>
    </mat-card>
    <mat-accordion multi="true" cdkDropList (cdkDropListDropped)="dropEducation($event)">
      <mat-expansion-panel expanded="{{currentProfile.educations.length < 2}}">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <strong>{{'edu.Add' | translate}}</strong>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
          <app-education-form [currentEducation]={} (formResult)="saveEducation($event)"> </app-education-form>
        </ng-template>
      </mat-expansion-panel>

      <mat-expansion-panel *ngFor="let education of currentProfile.educations" class="cdk-cursor" cdkDrag>
        <mat-expansion-panel-header>
          <mat-panel-title class="flex-grow-2-on-mobile-otherwise-1">
            {{education.nameInstitute}} - {{education.nameEducationNL}}
          </mat-panel-title>
          <mat-panel-description>
            {{education.startDate}} - {{education.endDate}}
          </mat-panel-description>
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
          <app-education-form [currentEducation]="education" (formResult)="saveEducation($event)" (deleteEdu)="deleteEducation($event)"> </app-education-form>
        </ng-template>
      </mat-expansion-panel>
    </mat-accordion>
    <div class="drag-drop-text-container">
      <mat-hint *ngIf="currentProfile.educations.length > 1" translate>DragDrop</mat-hint>
    </div>
  </mat-card-content>
</mat-card>
