<mat-card>
  <mat-card-content>
    <h3>{{'side.Interests' | translate}}  {{currentProfile.professional.firstName + " " + currentProfile.professional.lastName}}</h3>
    <mat-card *ngIf="errorMessage">
      <mat-card-content>
        <mat-error>{{errorMessage | translate}}</mat-error>
      </mat-card-content>
    </mat-card>

    <mat-accordion multi="true" cdkDropList (cdkDropListDropped)="dropInterest($event)">
      <mat-expansion-panel expanded="{{currentProfile.interests.length < 2}}">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <strong>{{'int.Add' | translate}}</strong>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
          <app-interest-form [currentInterest]="" (formResult)="saveInterest($event)"> </app-interest-form>
        </ng-template>
      </mat-expansion-panel>

      <mat-expansion-panel *ngFor="let interest of currentProfile.interests" class="cdk-cursor" cdkDrag>
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{interest.interestNL}}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
          <app-interest-form [currentInterest]="interest" (formResult)="saveInterest($event)" (deleteInt)="deleteInterest($event)"> </app-interest-form>
        </ng-template>
      </mat-expansion-panel>
    </mat-accordion>
    <div class="drag-drop-text-container">
    <mat-hint *ngIf="currentProfile.interests.length > 1" translate>DragDrop</mat-hint>
    </div>
  </mat-card-content>
</mat-card>

