export class Professional {
  id: number;
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  placeOfResidence: string;
  company: Company;
  photoId: string;
  photoName: string;
  photoUrl: string;
  phoneNumber: string;
  emailAddress: string;
  address: string;
  zipcode: string;
  dietaryRequirements: string;
  partner: string;
  children: string;
  usePhoto: boolean;
  groups: string[] = [];

}

export enum Company {
  GROUP9 = 'group9',
  HAYSTAQ = 'haystaq',
  MSTACK = 'mstack',
  BRIGHTR = 'bright-r',
  ALFAONE = 'alfa1',
  BLUPOINT = 'blupoint',
  ADAPTIF = 'adaptif',
  N_ABLED = 'n-abled',
  MERAPAR = 'merapar',
  UNKNOWN = 'unknown'
}
