<form class="d-flex flex-column gap-10 max-w-50p-on-pc" novalidate
      [formGroup]="personalDetailsForm" (ngSubmit)="savePersonalDetails(personalDetailsForm, formDirective)"
      #formDirective="ngForm">

  <mat-form-field>
    <mat-label>{{ 'pd.FirstName' | translate }}</mat-label>
    <input matInput formControlName="firstName">
    <mat-error *ngIf="personalDetailsForm.get('firstName').errors">
      {{'invalid' | translate }}
    </mat-error>
  </mat-form-field>
  <mat-form-field>
    <mat-label>{{ 'pd.LastName' | translate }}</mat-label>
    <input matInput formControlName="lastName">
    <mat-error *ngIf="personalDetailsForm.get('lastName').errors">
      {{'invalid' | translate }}
    </mat-error>
  </mat-form-field>
  <mat-form-field>
    <mat-label>{{ 'pd.Title' | translate }}</mat-label>
    <input matInput formControlName="title">
    <mat-hint translate>pd.titleHint</mat-hint>
  </mat-form-field>

  <mat-form-field>
    <mat-label>{{ 'pd.address' | translate }}</mat-label>
    <input matInput formControlName="address">
    <mat-hint translate>pd.personalDetailsHint</mat-hint>
  </mat-form-field>
  <mat-form-field>
    <mat-label>{{ 'pd.zipcode' | translate }}</mat-label>
    <input matInput formControlName="zipcode">
    <mat-hint translate>pd.personalDetailsHint</mat-hint>
  </mat-form-field>
  <mat-form-field>
    <mat-label>{{ 'pd.CityOfResidence' | translate }}</mat-label>
    <input matInput formControlName="placeOfResidence">
  </mat-form-field>

  <mat-form-field>
    <mat-label>{{ 'pd.PhoneNumber' | translate }}</mat-label>
    <input matInput formControlName="phoneNumber">
    <mat-hint translate>pd.personalDetailsHint</mat-hint>
  </mat-form-field>
  <mat-form-field>
    <mat-label>{{ 'pd.Email' | translate }}</mat-label>
    <input matInput formControlName="emailAddress">
    <mat-hint translate>pd.personalDetailsHint</mat-hint>
  </mat-form-field>

  <mat-form-field>
    <mat-label>{{ 'pd.DateOfBirth' | translate }}</mat-label>
    <input matInput [matDatepicker]="picker" formControlName="dateOfBirth">
    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker startView="multi-year"></mat-datepicker>
  </mat-form-field>


  <mat-form-field>
    <mat-label>{{ 'pd.partner' | translate }}</mat-label>
    <input matInput formControlName="partner">
    <mat-hint translate>pd.personalDetailsHint</mat-hint>
  </mat-form-field>
  <mat-form-field>
    <mat-label>{{ 'pd.children' | translate }}</mat-label>
    <input matInput formControlName="children">
    <mat-hint translate>pd.personalDetailsHint</mat-hint>
  </mat-form-field>
  <mat-form-field>
    <mat-label>{{ 'pd.dietaryRequirements' | translate }}</mat-label>
    <textarea matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="3"
              formControlName="dietaryRequirements"></textarea>
    <mat-hint translate>pd.personalDetailsHint</mat-hint>
  </mat-form-field>
  <mat-checkbox formControlName="usePhoto">{{ 'pd.usePhoto' | translate }}</mat-checkbox>
  <h5 translate>pd.groups</h5>
  <div formArrayName="groups" *ngFor="let item of personalDetailsForm.get('groups')['controls']; let i = index;">
    <mat-checkbox formControlName="{{i}}">{{availableGroups[i].name}}</mat-checkbox>
  </div>
  <div>
    <button type="submit" mat-raised-button color="primary"
            [disabled]="personalDetailsForm.pristine">{{'Submit' | translate}}</button>
  </div>
</form>

