import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FacebookComponent} from './facebook.component';
import {RouterModule} from "@angular/router";
import {facebookRoutes} from "./facebook.routes";
import {MatExpansionModule} from "@angular/material/expansion";
import {MatIconModule} from "@angular/material/icon";
import {MatCardModule} from "@angular/material/card";
import {MatListModule} from "@angular/material/list";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {FormsModule} from "@angular/forms";
import {TranslateModule} from "@ngx-translate/core";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";


@NgModule({
  declarations: [FacebookComponent],
    imports: [
        CommonModule,
        RouterModule.forChild(facebookRoutes),
        MatExpansionModule,
        MatIconModule,
        MatCardModule,
        MatListModule,
        MatCheckboxModule,
        FormsModule,
        TranslateModule.forChild(),
        MatProgressSpinnerModule
    ]
})
export class FacebookModule { }
