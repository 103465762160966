import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Role} from "../../model/role";
import {UserApiService} from "../../core/services/user-api.service";
import {User} from "../../model/user";
import {Company} from "../../model/professional";
import {Group} from "../../model/group";
import {Observable} from "rxjs";

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['../../shared/styles/cv.css']
})
export class AdminComponent implements OnInit {
  errorMessage: string;
  adminForm: UntypedFormGroup;

  addUserForm: UntypedFormGroup;
  deleteUserForm: UntypedFormGroup;

  addGroupForm: UntypedFormGroup;
  deleteGroupForm: UntypedFormGroup;

  availableUsers: User[];

  availableGroups: Group[];

  selectedUser: User;
  companies: string[] = Object.keys(Company);

  isAdmin$: Observable<boolean>;
  isSales$: Observable<boolean>;


  constructor(private api: UserApiService, private fb: UntypedFormBuilder) {
  }

  ngOnInit() {
    this.isAdmin$ = this.api.isAdmin$();
    this.isSales$ = this.api.isSales$();

    this.adminForm = this.fb.group({
      userId: ['', Validators.required],
      roles: this.fb.group({
        admin: [false],
        sales: [false],
        professional: [false]
      })
    });
    this.addUserForm = this.fb.group({
      name: ['', Validators.required],
      company: ['', Validators.required],
      email: ['', Validators.required]
    });
    this.deleteUserForm = this.fb.group({
      id: ['', Validators.required],
    });

    this.addGroupForm = this.fb.group({
      name: ['', Validators.required]
    });
    this.deleteGroupForm = this.fb.group({
      id: ['', Validators.required],
    });

    this.getAvailableUsers();
    this.getAvailableGroups();
    this.selectUserWatch();
  }

  getAvailableUsers(): void {
    this.api.getAllUsers()
      .subscribe({
        next: (users: User[]) => {
          this.availableUsers = users
        }
      });
  }

  getAvailableGroups(): void {
    this.api.getAllGroups()
      .subscribe({
        next: (groups: Group[]) => {
          this.availableGroups = groups
        }
      });
  }


  getUser(userId) {
    this.api.getUser(userId).subscribe({
      next: (user: User) => {
        this.selectedUser = user;
        this.setExistingRoles()
      }
    })
  }

  setExistingRoles() {
    console.log(this.selectedUser.roles)
    this.adminForm.controls.roles['controls'].admin.setValue(this.selectedUser.roles.indexOf(Role.ADMIN) > -1)
    this.adminForm.controls.roles['controls'].sales.setValue(this.selectedUser.roles.indexOf(Role.SALES) > -1)
    this.adminForm.controls.roles['controls'].professional.setValue(this.selectedUser.roles.indexOf(Role.PROFESSIONAL) > -1)
  }

  selectUserWatch() {
    this.adminForm.get('userId').valueChanges.subscribe(value => {
      this.getUser(this.adminForm.get('userId').value);
    });
  }

  saveRoles() {
    if (this.adminForm.valid) {
      if (this.adminForm.dirty) {
        //clear existing roles.
        this.selectedUser.roles = [];
        this.selectedUser.roles.push(Role.AUTHENTICATED)
        if (this.adminForm.controls.roles['controls'].admin.value) {
          this.selectedUser.roles.push(Role.ADMIN)
        }
        if (this.adminForm.controls.roles['controls'].sales.value) {
          this.selectedUser.roles.push(Role.SALES)
        }
        if (this.adminForm.controls.roles['controls'].professional.value) {
          this.selectedUser.roles.push(Role.PROFESSIONAL)
        }
        console.log(this.selectedUser)
        this.api.updateUser(this.selectedUser).subscribe({
            next: () => this.onSaveComplete(this.adminForm),
            error: err => this.errorMessage = err.messsage
        })
      } else {
        this.onSaveComplete(this.adminForm);
      }
    } else {
      this.errorMessage = 'correct.values';
    }
  }

  addUser() {
    if (this.addUserForm.valid) {
      if (this.addUserForm.dirty) {
        const u: User = {...this.addUserForm.value}
        console.log(u)
        this.api.addUser(u).subscribe({
          next: () => this.onSaveComplete(this.addUserForm),
          error: err => this.errorMessage = err.messsage
        })
      } else {
        this.onSaveComplete(this.addUserForm);
      }
    } else {
      this.errorMessage = 'correct.values';
    }
  }

  deleteUser() {
    if (this.deleteUserForm.valid) {
      if (this.deleteUserForm.dirty) {
        const u: User = {...this.deleteUserForm.value}
        console.log(u)
        this.api.deleteUser(u).subscribe({
          next: () => this.onSaveComplete(this.deleteUserForm),
          error: err => this.errorMessage = err.messsage
        })
      } else {
        this.onSaveComplete(this.deleteUserForm);
      }
    } else {
      this.errorMessage = 'correct.values';
    }
  }


  addGroup() {
    if (this.addGroupForm.valid) {
      if (this.addGroupForm.dirty) {
        const g: Group = {...this.addGroupForm.value}
        console.log(g)
        this.api.addGroup(g).subscribe({
          next: () => this.onSaveComplete(this.addGroupForm),
          error: err => this.errorMessage = err.messsage
        })
      } else {
        this.onSaveComplete(this.addGroupForm);
      }
    } else {
      this.errorMessage = 'correct.values';
    }
  }

  deleteGroup() {
    if (this.deleteGroupForm.valid) {
      if (this.deleteGroupForm.dirty) {
        const g: Group = {...this.deleteGroupForm.value}
        console.log(g)
        this.api.deleteGroup(g).subscribe({
          next: () => this.onSaveComplete(this.deleteGroupForm),
          error: err => this.errorMessage = err.messsage
        })
      } else {
        this.onSaveComplete(this.deleteGroupForm);
      }
    } else {
      this.errorMessage = 'correct.values';
    }
  }

  onSaveComplete(form: UntypedFormGroup): void {
    // Reset the form to clear the flags
    form.reset();
    this.getAvailableUsers();
    this.getAvailableGroups();
  }
}
