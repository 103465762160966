import {Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {OidcSecurityService, LoginResponse} from "angular-auth-oidc-client";
import {Router} from "@angular/router";


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  localStorageRedirectKey = 'redirect';
  loaded = false;
  title = 'PRO';
  sidebarOpen = true;
  sidebarMode = "side";
  hasUserProfile: boolean = false;
  isAuthenticated: boolean = false;

  constructor(private translate: TranslateService, private oidcSecurityService: OidcSecurityService, private router: Router) {
    this.translate.setDefaultLang('nl');
    this.translate
      .use('nl')
      .subscribe((res: any) => {
        this.loaded = true;
      });
  }

  public ngOnInit(): void {
    this.oidcSecurityService
      .checkAuth()
      .subscribe((response: LoginResponse) => {
        if (!response.isAuthenticated) {
          if ('/autologin' !== window.location.pathname) {
            this.storeRedirectPath(window.location.pathname);
            this.router.navigate(['/autologin']);
          }
        }
        if (response.isAuthenticated) {
          this.navigateToRedirectPath();
          this.isAuthenticated = true;
        }
      });
  }

  public toggleSidebar(navbarOpen: boolean): void {
    this.sidebarOpen = navbarOpen;
  }

  public setSidebarMode(navbarMode: string): void {
    this.sidebarMode = navbarMode;
  }

  private navigateToRedirectPath() {
    const path = localStorage.getItem(this.localStorageRedirectKey);
    localStorage.removeItem(this.localStorageRedirectKey);

    if (!path || path === this.router.url) {
      return;
    }

    if (path.toString().includes('/unauthorized')) {
      this.router.navigate(['/']);
    } else {
      this.router.navigate([path]);
    }
  }

  private storeRedirectPath(path: string): void {
    localStorage.setItem(this.localStorageRedirectKey, path);
  }
}
