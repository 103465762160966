export class SpokenLanguage {
    id: number;
    nameNL: string;
    nameEN: string;
    proficiency: Proficiency;
}

export enum Proficiency {
    NATIVE = 'NATIVE',
    FULL_PROFESSIONAL = 'FULL_PROFESSIONAL',
    PROFESSIONAL = 'PROFESSIONAL',
    LIMITED = 'LIMITED',
    ELEMENTARY = 'ELEMENTARY'
}
