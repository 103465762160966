import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormGroupDirective, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Interest} from "../../../model/interests";
import {Formcontainer} from "../../../model/formcontainer";

@Component({
  selector: 'app-interest-form',
  templateUrl: './interest-form.component.html',
  styleUrls: ['../../../shared/styles/cv.css']
})
export class InterestFormComponent implements OnInit {

  @Input()
  currentInterest: Interest;
  interestForm: UntypedFormGroup;

  @Output()
  formResult: EventEmitter<Formcontainer> = new EventEmitter();

  @Output()
  deleteInt: EventEmitter<any> = new EventEmitter();

  constructor(private fb: UntypedFormBuilder) { }

  ngOnInit() {
    this.interestForm = this.fb.group({
      interestNL: ['', Validators.required],
      interestEN: ['', Validators.required],
      id: [null],
    });
    if (this.currentInterest != null) {
      this.interestForm.patchValue(this.currentInterest);
    }
  }

  saveInterest(form: UntypedFormGroup, formDirective: FormGroupDirective) {
    this.formResult.emit({form: form, formDirective: formDirective});
  }
  deleteInterest() {
    this.deleteInt.emit(this.interestForm)
  }

}
