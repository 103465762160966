import {Component, EventEmitter, HostListener, OnInit, Output} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Observable, Subscription} from "rxjs";
import { map } from 'rxjs/operators';
import {OidcSecurityService} from "angular-auth-oidc-client";
import {UserApiService} from "../../../core/services/user-api.service";

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['../../../components/dashboard/dashboard.css']

})
export class NavBarComponent implements OnInit {
  isAuthenticated$: Observable<boolean>;
  isAdmin$: Observable<boolean>;
  isSales$: Observable<boolean>;
  isProfessional$: Observable<boolean>;

  watcher: Subscription;
  activeMediaQuery = '';

  language = 'nl';

  navbarOpen = true;
  navbarMode;
  @Output()
  navbarOpenEmitter: EventEmitter<any> = new EventEmitter();

  @Output()
  navbarModeEmitter: EventEmitter<any> = new EventEmitter();


  constructor(
    private translate: TranslateService,
    public oidcSecurityService: OidcSecurityService,
    private userApiService: UserApiService) {
  }

  @HostListener('window:resize')
  private onWindowResize() { 
    if (this.isMobile()) {
      this.navbarOpen = false;
      this.navbarMode = "over";
    } else {
      this.navbarOpen = true
      this.navbarMode = "side";
    }

    this.navbarOpenEmitter.emit(this.navbarOpen)
    this.navbarModeEmitter.emit(this.navbarMode)
  }

  ngOnInit() {
    this.isAuthenticated$ = this.oidcSecurityService.isAuthenticated$.pipe(map(result => result.isAuthenticated));

    this.navbarOpen = !this.isMobile();
    this.navbarOpenEmitter.emit(this.navbarOpen)

    this.navbarMode = this.isMobile() ? "push" : "side";
    this.navbarModeEmitter.emit(this.navbarMode)
    this.isAdmin$ = this.userApiService.isAdmin$();
    this.isProfessional$ = this.userApiService.isProfessional$();
    this.isSales$ = this.userApiService.isSales$();
  }

  setLanguage(language: string) {
    this.language = language;
    this.translate.use(language);
  }

  login() {
    this.oidcSecurityService.authorize();
  }

  logout() {
    sessionStorage.removeItem('userId');
    sessionStorage.removeItem('currentProfileId');
    console.log('Session has been cleared');
    this.oidcSecurityService.logoff().subscribe(console.log);
  }

  toggleNavbar() {
    this.navbarOpen = !this.navbarOpen;
    this.navbarOpenEmitter.emit(this.navbarOpen)
  }

  public showToggleNavbar(): boolean {
    return this.navbarMode !== 'side';
  }

  public isMobile(): boolean {
    // For backwards compatibility with @angular/flex-layout
    // See: https://github.com/angular/flex-layout/wiki/Responsive-API#mediaqueries-and-aliases
    const xs = 600;

    return window.innerWidth < xs;
  }
}
