<mat-nav-list>
  @if (hasSalesProfileSelected() && (isAuthenticated$ && isSales$ | async)) {
    <a mat-list-item class="list-item-two-lines" (click)="resetProfile()">
      <span matListItemLine>
        <mat-icon matListItemIcon>notification_important</mat-icon>
        <span matListItemTitle>{{ "side.ProfileSelected" | translate }}</span>
      </span>
      <span matListItemLine>
        <mat-icon matListItemIcon>notification_important</mat-icon>
        <span matListItemTitle>{{ "side.ResetProfile" | translate }}</span>
      </span>
    </a>
    <hr/>
  }
  @if (isAuthenticated$ && isProfessional$ | async) {
    <a mat-list-item [routerLink]="['/personal-details']">
      <mat-icon matListItemIcon>face</mat-icon>
      <span matListItemTitle>{{"side.PersonalDetails" | translate}}</span>
    </a>
    <a mat-list-item [routerLink]="['/photo']">
      <mat-icon matListItemIcon>list</mat-icon>
      <span matListItemTitle>{{"side.Photo" | translate}}</span>
    </a>
    <a mat-list-item [routerLink]="['/summary']">
      <mat-icon matListItemIcon>add_a_photo</mat-icon>
      <span matListItemTitle>{{"side.Summary" | translate}}</span>
    </a>
    <a mat-list-item [routerLink]="['/assignments']">
      <mat-icon matListItemIcon>work</mat-icon>
      <span matListItemTitle>{{"side.Assignments" | translate}}</span>
    </a>
    <a mat-list-item [routerLink]="['/tools']">
      <mat-icon matListItemIcon>build</mat-icon>
      <span matListItemTitle>{{"side.Tools" | translate}}</span>
    </a>
    <a mat-list-item [routerLink]="['/soft-skills']">
      <mat-icon matListItemIcon>mic</mat-icon>
      <span matListItemTitle>{{"side.SoftSkills" | translate}}</span>
    </a>
    <a mat-list-item [routerLink]="['/education']">
      <mat-icon matListItemIcon>school</mat-icon>
      <span matListItemTitle>{{"side.Education" | translate}}</span>
    </a>
    <a mat-list-item [routerLink]="['/certificates']">
      <mat-icon matListItemIcon>security</mat-icon>
      <span matListItemTitle>{{"side.Certificates" | translate}}</span>
    </a>
    <a mat-list-item [routerLink]="['/interests']">
      <mat-icon matListItemIcon>sports_soccer</mat-icon>
      <span matListItemTitle>{{"side.Interests" | translate}}</span>
    </a>
    <a mat-list-item [routerLink]="['/languages']">
      <mat-icon matListItemIcon>language</mat-icon>
      <span matListItemTitle>{{"side.Languages" | translate}}</span>
    </a>
  }
</mat-nav-list>
