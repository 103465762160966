<mat-card>
  <mat-card-content>
    <h3>{{'side.SoftSkills' | translate}}  {{currentProfile.professional.firstName + " " + currentProfile.professional.lastName}}</h3>
    <mat-card *ngIf="errorMessage">
      <mat-card-content>
        <mat-error>{{errorMessage | translate}}</mat-error>
      </mat-card-content>
    </mat-card>

    <mat-accordion multi="true" cdkDropList (cdkDropListDropped)="dropSoftSkill($event)">
      <mat-expansion-panel expanded="{{currentProfile.softSkills.length < 2}}">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <strong>{{'softSkill.Add' | translate}}</strong>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
          <app-soft-skill-form [currentSoftSkill]="" (formResult)="saveSoftSkill($event)"> </app-soft-skill-form>
        </ng-template>
      </mat-expansion-panel>

      <mat-expansion-panel *ngFor="let softSkill of currentProfile.softSkills" class="cdk-cursor" cdkDrag>
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{softSkill.nameSoftSkillNL}}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
          <app-soft-skill-form [currentSoftSkill]="softSkill" (formResult)="saveSoftSkill($event)" (deleteTl)="deleteSoftSkill($event)"> </app-soft-skill-form>
        </ng-template>
      </mat-expansion-panel>
    </mat-accordion>
    <div class="drag-drop-text-container">
      <mat-hint *ngIf="currentProfile.softSkills.length > 1" translate>DragDrop</mat-hint>
    </div>
  </mat-card-content>
</mat-card>
