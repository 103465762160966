import {BrowserModule} from '@angular/platform-browser';
import {LOCALE_ID, NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import { HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {NgbDateAdapter, NgbDateNativeAdapter, NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {AppComponent} from './app.component';
import {appRoutes} from './app-routing.module';

import {DownloadModule} from './download/download.module';
import {ProfileModule} from "./profile/profile.module";
import {AdminModule} from './admin/admin.module';

import {NavBarComponent, SideBarComponent} from './shared/index';

import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {
  DateAdapter,
  ErrorStateMatcher,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  ShowOnDirtyErrorStateMatcher
} from '@angular/material/core';


import {DashboardComponent} from './components/dashboard/dashboard.component';
import {FacebookModule} from "./facebook/facebook.module";
import {MatSidenavModule} from "@angular/material/sidenav";
import {MatToolbarModule} from "@angular/material/toolbar";
import {MatIconModule} from "@angular/material/icon";
import {MatListModule} from '@angular/material/list';
import {MatButtonModule} from "@angular/material/button";
import {MatCardModule} from "@angular/material/card";
import {AuthModule, LogLevel, PassedInitialConfig} from "angular-auth-oidc-client";
import {interceptorProviders} from "./core/interceptor/http-interceptors";
import {LocationStrategy, PathLocationStrategy} from "@angular/common";
import {AutoLoginComponent} from "./core/auto-login/auto-login.component";
import {UnauthorizedComponent} from "./core/unauthorized/unauthorized.component";
import {ForbiddenComponent} from "./core/forbidden/forbidden.component";
import {MomentDateAdapter} from "@angular/material-moment-adapter";
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

const authConfig: PassedInitialConfig = {
  config: {
    authority: 'https://login.microsoftonline.com/49445e6c-4079-4692-8349-8bb3853f22fc/v2.0/',
    redirectUrl: `${window.location.origin}/home`,
    clientId: 'aa4580d2-980e-4e6b-bd0f-3c22b45473a7',
    scope: 'openid profile email offline_access',
    responseType: 'code',
    triggerAuthorizationResultEvent: true,
    postLogoutRedirectUri: `${window.location.origin}/unauthorized`,
    startCheckSession: false,
    silentRenew: true,
    silentRenewUrl: `${window.location.origin}/assets/auth/silent-renew.html`,
    postLoginRoute: '/home',
    forbiddenRoute: '/forbidden',
    unauthorizedRoute: '/unauthorized',
    logLevel: LogLevel.Debug,
    historyCleanupOff: true,
    maxIdTokenIatOffsetAllowedInSeconds: 600,
  }
};

export const DateFormats = {
  parse: {
    dateInput: ['DD-MM-YYYY']
  },
  display: {
    dateInput: 'DD-MM-YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    UnauthorizedComponent,
    ForbiddenComponent,
    NavBarComponent,
    SideBarComponent,
    AutoLoginComponent
  ],
  bootstrap: [AppComponent],
  exports: [RouterModule],
  imports: [
    AuthModule.forRoot(authConfig),
    BrowserModule,
    DownloadModule,
    FacebookModule,
    AdminModule,
    RouterModule.forRoot(appRoutes, {}),
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    BrowserAnimationsModule,
    ProfileModule,
    MatSidenavModule,
    MatToolbarModule,
    MatIconModule,
    MatListModule,
    MatButtonModule,
    MatCardModule,
    MatProgressSpinnerModule
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'nl-NL' },
    { provide: NgbDateAdapter, useClass: NgbDateNativeAdapter },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: DateFormats },
    { provide: ErrorStateMatcher, useClass: ShowOnDirtyErrorStateMatcher },
    interceptorProviders,
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    provideHttpClient(withInterceptorsFromDi())
  ]
})
export class AppModule {
}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
