import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import {inject} from '@angular/core';
import {take} from "rxjs/operators";
import {UserApiService} from "../services/user-api.service";
import {Group} from "../../model/group";

export const groupsResolver: ResolveFn<Group[]> = (route: ActivatedRouteSnapshot) => {
  const userApiService = inject(UserApiService);
  return userApiService.getAllGroups().pipe(
    take(1)
  );
};
