export class SoftSkill {
  id: number;
  nameSoftSkillNL: string;
  nameSoftSkillEN: string;
  level: SoftSkillLevel;
}

export enum SoftSkillLevel {
  BASIC = 'BASIC',
  COMPETENT = 'COMPETENT',
  ADVANCED = 'ADVANCED',
  EXPERT = 'EXPERT'
}

