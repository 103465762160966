import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import {inject} from '@angular/core';
import {ProfileApiService} from "../services/profile-api.service";
import {Profile} from "../../model/profile";
import {switchMap, take} from "rxjs/operators";
import {UserApiService} from "../services/user-api.service";
import {User} from "../../model/user";
import {Role} from "../../model/role";

export const profileResolver: ResolveFn<Profile> = (route: ActivatedRouteSnapshot) => {
  const profileApiService = inject(ProfileApiService);
  const userApiService = inject(UserApiService);
  
  return userApiService.getCurrentUser().pipe(
    switchMap(currentUser => profileApiService.getProfile(getProfileId(currentUser))),
    take(1)
  );
};

function getProfileId(currentUser: User): string {
  let profileId = currentUser.profileId;
  if (currentUser.roles.indexOf(Role.SALES) > -1 && sessionStorage.getItem('activeProfileId') != null) {
    profileId = sessionStorage.getItem('activeProfileId');
  }
  return profileId;
}
