import {Component, OnInit} from '@angular/core';
import {FileItem, FileLikeObject, FileUploader, ParsedResponseHeaders} from "ng2-file-upload";
import {Profile} from "../../../model/profile";
import {ProfileApiService} from "../../../core/services/profile-api.service";
import {ActivatedRoute, Router} from "@angular/router";
import {OidcSecurityService} from "angular-auth-oidc-client";

const URL = '/api/profiles/photo/';

@Component({
  selector: 'app-photo',
  templateUrl: './photo.component.html',
  styleUrls: ['../../../shared/styles/cv.css']
})
export class PhotoComponent implements OnInit {
  readonly currentProfile: Profile;
  uploader: FileUploader;
  response: string;

  errorMessage: string;
  isImageLoading: boolean = false;

  constructor(private route: ActivatedRoute, private api: ProfileApiService, private oidcSecurityService: OidcSecurityService, private router: Router) {
    this.currentProfile = route.snapshot.data.currentProfile;
    this.response = '';
  }

  public ngOnInit(): void {
    this.loadProfile(this.currentProfile)
  }

  private loadProfile(profile: Profile): void {
    if (!profile.professional.photoName) {
      this.isImageLoading = true;
    }
    let idToken = this.getIdToken();
    this.uploader = new FileUploader({
      url: URL + this.currentProfile.id,
      autoUpload: true,
      queueLimit: 1,
      allowedMimeType: ['image/jpeg', 'image/png'],
      maxFileSize: 5 * 1024 * 1024, // 5 MB
      authToken: "Bearer " + idToken,
      headers: [{name: 'Accept', value: 'application/json'}],
    });
    this.uploader.response.subscribe(res => this.response = res);
    this.uploader.onSuccessItem = (item: FileItem, response: string, status: number, headers: ParsedResponseHeaders) => {
      this.isImageLoading = false;
      console.log("onSuccessItem " + status, item);
      this.loadProfile(this.currentProfile);
      this.redirectTo("photo");
    }
    this.uploader.onErrorItem = (item: FileItem, response: string, status: number, headers: ParsedResponseHeaders) => {
      console.log("onErrorItem " + status, item);
      this.errorMessage = "Het is niet mogelijk dit bestand toe te voegen. Gebruik een .jpg file van maximaal 5Mb groot."
    }
    this.uploader.onWhenAddingFileFailed = (item: FileLikeObject, response: string, status: number) => {
      console.log("onWhenAddingFileFailed " + status, item);
      this.errorMessage = "Het is niet mogelijk dit bestand toe te voegen. Gebruik een .jpg file van maximaal 5Mb groot."
    }
  }

  public deletePhoto() {
    this.api.deleteCurrentPhoto(this.currentProfile.id).subscribe(res => {
      this.currentProfile.professional.photoId = null;
      this.currentProfile.professional.photoName = null;
      this.currentProfile.professional.photoUrl = null;
      this.loadProfile(this.currentProfile);
    });
  }

  private redirectTo(uri:string){
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
      this.router.navigate([uri]));
  }

  private getIdToken(): string {
    let idToken = '';
    this.oidcSecurityService.getIdToken().subscribe(token => idToken = token);
    return idToken;
  }
}
