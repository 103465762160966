<div>
  <mat-card *ngIf="errorMessage">
    <mat-card-content>
      <mat-error>{{errorMessage | translate}}</mat-error>
    </mat-card-content>
  </mat-card>
  <mat-card>
    <mat-card-content>
      <h3>{{'side.PersonalDetails' | translate}}  {{currentProfile.professional.firstName + " " + currentProfile.professional.lastName}}</h3>
      <app-personal-details-form [currentPersonalDetails]="currentProfile.professional"
                                [availableGroups]="availableGroups"
                                (formResult)="savePersonalDetails($event)"></app-personal-details-form>
    </mat-card-content>
  </mat-card>
</div>
