<mat-card>
  <mat-card-content>

    <mat-card *ngIf="errorMessage">
      <mat-error>{{errorMessage}}</mat-error>
    </mat-card>
    <form class="d-flex flex-column max-w-50p-on-pc" novalidate [formGroup]="cvForm">
      <h3>{{'nav.DownloadCV' | translate}}  {{currentProfile.professional.firstName + " " + currentProfile.professional.lastName}}</h3>
      <mat-form-field class="flex">
        <mat-label translate>cv.ProfileId</mat-label>
        <mat-select formControlName="profileId">
          <mat-option *ngFor="let availableUser of availableUsers" [value]="availableUser.profileId">
            {{availableUser.name}} ({{ "cv." + availableUser.company | translate}})
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="flex">
        <mat-label translate>cv.Language</mat-label>
        <mat-select formControlName="language">
          <mat-option *ngFor="let language of languages" [value]="language">
            {{"cv." + language | translate}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="flex">
        <mat-label translate>cv.Template</mat-label>
        <mat-select formControlName="templateName">
          <mat-option *ngFor="let templateName of templateNames" [value]="templateName">
            {{"cv." + templateName | translate}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <div class="mat-form-field" style="margin-bottom: 10px;">
        <mat-checkbox #usePhotoCheckbox formControlName="usePhoto"></mat-checkbox>
        <mat-label translate>cv.usePhoto</mat-label>
        <p class="warning" [hidden]="!usePhotoCheckbox.disabled">{{ 'cv.usePhotoWarning' | translate }}</p>
      </div>

      <button type="button" (click)="generateCV()" mat-raised-button color="primary" [disabled]="!cvForm.valid || generating">
        @if (generating) {
          <mat-spinner diameter="16" style="float: left; margin-right: 5px;"></mat-spinner>
          {{'cv.Generating' | translate}}
        } @else {
          {{'cv.Generate' | translate}}
        }
      </button>
      <br />

      <mat-expansion-panel> 
        <mat-expansion-panel-header>
          <mat-panel-title translate>cv.options</mat-panel-title>
        </mat-expansion-panel-header>

        <div class="d-flex flex-column-on-mobile-otherwise-row gap-20">
          <mat-form-field class="flex">
            <mat-label translate="">cv.selectSummary</mat-label>
            <mat-select formControlName="summaryId">
              <mat-option *ngFor="let summary of currentProfile.summaries" [value]="summary.id">{{summary.name}}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="flex">
            <mat-label translate="">cv.selectAssignments</mat-label>
            <mat-select formControlName="selectAssignments" multiple>
              <mat-option *ngFor="let assignment of currentProfile.assignments" [value]="assignment.id">{{assignment.roleNL}} - {{assignment.organisationNL}} ({{assignment.startDate}} - {{assignment.endDate}})  </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="d-flex flex-column-on-mobile-otherwise-row gap-20">
          <mat-form-field class="flex">
            <mat-label translate>cv.max.assignmentDetail</mat-label>
            <mat-select formControlName="maxAssignmentsDetail">
              <mat-option *ngFor="let nr of [].constructor(currentProfile.assignments.length +1); let i=index" [value]="+i">
                {{i}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="flex">
            <mat-label translate>cv.max.assignmentSummary</mat-label>
            <mat-select formControlName="maxAssignmentsSummary">
              <mat-option *ngFor="let nr of [].constructor(currentProfile.assignments.length +1); let i=index" [value]="+i">
                {{i}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="d-flex flex-column-on-mobile-otherwise-row gap-20">
          <mat-form-field class="flex">
            <mat-label translate>cv.max.education</mat-label>
            <mat-select formControlName="maxEducation">
              <mat-option *ngFor="let nr of [].constructor(currentProfile.educations.length +1); let i=index" [value]="+i">
                {{i}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="flex">
            <mat-label translate>cv.max.certificate</mat-label>
            <mat-select formControlName="maxCertificates">
              <mat-option *ngFor="let nr of [].constructor(currentProfile.certificates.length +1); let i=index" [value]="+i">
                {{i}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

  <!-- Unused but supported
      <mat-form-field class="flex">
        <mat-label translate>cv.max.tool</mat-label>
        <mat-select formControlName="maxTools">
          <mat-option *ngFor="let nr of [].constructor(currentProfile.tools.length +1); let i=index" [value]="+i">
            {{i}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="flex">
        <mat-label translate>cv.max.personalInterest</mat-label>
        <mat-select formControlName="maxPersonalInterest">
          <mat-option *ngFor="let nr of [].constructor(currentProfile.interests.length +1); let i=index" [value]="+i">
            {{i}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="flex">
        <mat-label translate>cv.max.certificateSummary</mat-label>
        <mat-select formControlName="maxCertificatesSummary">
          <mat-option *ngFor="let nr of [].constructor(currentProfile.certificates.length +1); let i=index" [value]="+i">
            {{i}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="flex">
        <mat-label translate>cv.max.language</mat-label>
        <mat-select formControlName="maxLanguages">
          <mat-option *ngFor="let nr of [].constructor(currentProfile.languages.length +1); let i=index" [value]="+i">
            {{i}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="flex">
        <mat-label translate>cv.max.languageSummary</mat-label>
        <mat-select formControlName="maxLanguagesSummary">
          <mat-option *ngFor="let nr of [].constructor(currentProfile.languages.length +1); let i=index" [value]="+i">
            {{i}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="flex">
        <mat-label translate>cv.max.educationSummary</mat-label>
        <mat-select formControlName="maxEducationSummary">
          <mat-option *ngFor="let nr of [].constructor(currentProfile.educations.length +1); let i=index" [value]="+i">
            {{i}}
          </mat-option>
        </mat-select>
      </mat-form-field>
  -->

      <button type="button" (click)="generateCV()" mat-raised-button color="primary"
              [disabled]="!cvForm.valid">{{'cv.Generate' | translate}}</button>
      </mat-expansion-panel>

    </form>

  </mat-card-content>
</mat-card>
