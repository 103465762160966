import {Component, OnInit} from '@angular/core';
import {FacebookService} from "./facebook.service";
import {FacebookProfile} from "../model/facebookProfile";
import {Profile} from "../model/profile";
import {Group} from "../model/group";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-facebook',
  templateUrl: './facebook.component.html',
  styleUrls: ['./facebook.component.css']
})
export class FacebookComponent implements OnInit {
  readonly currentProfile: Profile;
  readonly allGroups: Group[];
  allProfiles: FacebookProfile[];

  filteredProfiles: FacebookProfile[];
  filterItems: Array<any> = [
    {
      value: 'GROUP9',
      checked: false
    },
    {
      value: 'MSTACK',
      checked: false
    },
    {
      value: 'HAYSTAQ',
      checked: false
    },
    {
      value: 'BRIGHTR',
      checked: false
    },
    {
      value: 'ALFAONE',
      checked: false
    },
  ];
  filterGroups: Array<any> = [];

  constructor(private route: ActivatedRoute, private facebookService: FacebookService) {
    this.currentProfile = route.snapshot.data.currentProfile;
    this.allGroups = route.snapshot.data.allGroups;
    this.allGroups.forEach(g => {
      this.filterGroups.push({value: g.name, checked: false})
    })
    let ownCompanyCheckbox = this.filterItems.find(f => f.value == this.currentProfile.professional.company.toString());
    ownCompanyCheckbox.checked = true;

  }

  ngOnInit() {
    this.facebookService.getAvailableProfiles()
      .subscribe({
        next: (profiles: FacebookProfile[]) => {
          this.allProfiles = profiles;
          this.filterProfiles();
        }
      });
  }

  filterProfiles() {
    let enabledCompanies: Array<any> = this.filterItems.filter(el => el.checked).map((el) => el.value);
    if (this.allProfiles) {
      this.filteredProfiles = this.allProfiles.filter(p => enabledCompanies.includes(p.company.toString()));
      let enabledGroups: Array<any> = this.filterGroups.filter(el => el.checked).map((el) => el.value);
      if (enabledGroups.length > 0) {
        this.filteredProfiles = this.filteredProfiles.filter(p => enabledGroups.some(g => p.groups.includes(g)));
      }
    }
  }


}
